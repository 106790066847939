<template>
    <div :style="{minHeight: fullHeight-60 + 'px', maxHeight: fullHeight-60 + 'px',overflowY:'auto'}"  class="file-page-big-box">
        <div>
            <div class="file-header-box">
                <div class="file-header-left">
                    <img @click="hidden" class="file-header-left-img" src="../../../assets/img/groupImg/setting-menu-1.png" alt="">
                </div>
                <div class="file-header-main">
                    <div v-for="(item,index) in titleList" :key="index"
                    @click="changeTitle(item)" class="file-header-main-title" 
                    :class="changeVal == item ? 'file-header-main-title-check' : ''" >
                        {{ item }}
                    </div>
                </div>
                <div class="file-header-right">
                    <img @click="hidden" class="file-header-right-img back-groud-detail" src="../../../assets/img/groupImg/back-groud-detail.svg" alt="">
                    <img @mouseover="moveMenu($event,true)" @mouseout="moveMenu($event,false)" class="file-header-right-img" src="../../../assets/img/groupImg/more.svg" alt="">
                </div>
            </div>
            <div class="file-content-box">
                <GroupMindFile @upgradeSave="upgradeSave" :fullWidth="fullWidth" :groupDataDetail="groupDataDetail" v-if="changeVal == getString(Strings.Mind_Group_Mind_Mapping)" />
                <GroupDataFile @upgradeSave="upgradeSave" :fullWidth="fullWidth" :groupDataDetail="groupDataDetail" v-else-if="changeVal == getString(Strings.Mind_Group_Information)" />
                <GroupMessageArea :groupId="groupDataDetail.id" :groupDataDetail="groupDataDetail" :fullHeight="fullHeight" v-else-if="changeVal == getString(Strings.Mind_Group_Message_Area)" />
            </div>
        </div>
        <MenuListBox :showMenu="showMenu" :showData="menuList" :menuPoint="menuPoint" :pointObj="pointObj" @checkMenuItem="checkMenuItem"  />
    </div>

</template>

<script>
import httpImageToPrefix from '../../../utils/httpImageToPrefix';
import GroupDataFile from './groupFileChild/GroupDataFile'
import GroupMindFile from './groupFileChild/GroupMindFile'
import GroupMessageArea from "../groupMessage/GroupMessageArea";
import { mapMutations } from "vuex";
import Strings from "../../../common/lang/strings";
import getString from "../../../common/lang/language";
import MenuListBox from  "./menuListBox/MenuListBox"
// import MenuListBox from "../menuListBox/MenuListBox"
export default {
  components: {
      GroupDataFile,
      GroupMindFile,
      MenuListBox,
      GroupMessageArea,
  },
  props: [
    'groupDataDetail',
    'fullWidth',
    'fullHeight',
    'messageArea',

  ],
  data() {
    return {
        titleList: [
            getString(Strings.Mind_Group_Mind_Mapping),
            getString(Strings.Mind_Group_Information),
            getString(Strings.Mind_Group_Message_Area)
            // '思维导图',
            // '资料',
            // '留言区'
        ],
        changeVal: getString(Strings.Mind_Group_Mind_Mapping),
        Strings:Strings,
        menuList: [
        '上传文档',
        '创建文件夹'
        ],
        pointObj: {

        },
        menuPoint: {
            val1: 'bottom',
            val2: 'left', 
            type: 'history'
        },
        showMenu:false
    }
  },
  created(){
      if(this.messageArea == "messageArea"){
          this.changeVal = getString(Strings.Mind_Group_Message_Area)
      }else{
          this.changeVal = getString(Strings.Mind_Group_Mind_Mapping)
      }
  },
  methods: {
    ...mapMutations([
        "setGroupDataDetail",
        "setRefreshMyGroupList",
        "changeGroupDataDetail",
        "setgroupCreatedMenu"
    ]),

    upgradeSave(boolean) {
        let obj = {
            show: false,
            usedSpaceUpgradeName: this.$getStringObj.getString(this.$Strings.Used_Space_Upgrade)
        }
        this.$emit('hiddenFilePage',obj)
    },

    checkMenuItem (val) {
        if (val.type == 'history') {
            if (val.name == "存储使用情况") {
                let obj = {
                    show: false,
                    usedSpaceUpgradeName: this.$getStringObj.getString(this.$Strings.Used_Space_Upgrade)
                }
                this.$emit('hiddenFilePage',obj)
            }
        }
    },

    moveMenu(e,show) {
        this.menuList = [
            // {
            //     name: '导图修改记录',
            //     // image: require('../../../../assets/img/groupImg/addGroupImage.png')
            // },
            {
                name: '存储使用情况',
                // image: require('../../../../assets/img/groupImg/helpImage.png')
            }
        ]
        this.pointObj = e.target.getBoundingClientRect();

        this.menuPoint = {
            val1: 'bottom',
            val2: 'left', 
            type: 'history'
        }
        if (show) {
            this.showMenu = true
            // this.setgroupCreatedMenu(true);
        } else {
            this.showMenu = false
            // this.setgroupCreatedMenu(false);
        }

        // this.checkMenu = show
    },

    getString(i) {
        return getString(i);
    },
    changeTitle(item) {
        this.changeVal = item
    },

    httpImageToPrefix(src) {
        return httpImageToPrefix(src)
    },

    hidden() {
        let obj = {show: false}
        this.$emit('hiddenFilePage',obj)
    }
 
  },
  watch: {

  }
}
</script>

<style lang="less" >
.file-page-big-box {
    background-color: #fbfbfb;
    // min-height:calc(100vh - 80px);
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background: rgb(239, 239, 239);
        border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background: #bfbfbf;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #bfbfbf;
    }
}
  .file-header-box{
      display: flex;
      .file-header-left{
          flex: 0 0 100px;
          min-height: 72px;
          text-align: center;
          line-height: 72px;
          font-size: 12px;
          .file-header-left-img {
              cursor: pointer;
          }
      }

      .file-header-main{
          flex: 1;
          width: 100%;
          min-height: 72px;
          line-height: 72px;
          color: #333;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          .file-header-main-title {
              display: inline-block;
              height: 36px;
              line-height: 36px;
              margin: 0 10px;
              font-size: 14px;
              border-bottom: 2px solid rgba(255,255,255,0);
              cursor: pointer;
          }
          .file-header-main-title-check {
              color: #FD492B;
              border-bottom: 2px solid #FD492B;
          }
      }

      .file-header-right{
          flex: 0 0 100px;
          min-height: 72px;
          text-align: center;
          line-height: 72px;
          font-size: 12px;
          .file-header-right-img {
            width: 19px;
              cursor: pointer;
          }
          .back-groud-detail {
              margin-right: 20px;
          }
      }

  }
//   .footer-botton-box-father {
//     //   width: 1211px;
//       position: fixed;
//       bottom: 0;
//     //   left: 50%;
//     //   transform: translate(-50%,0);

//   }

</style>