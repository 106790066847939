<template>
  <div class="mind-file-page">
      <!-- <div class="header-input">
            <div class="search-header-input">
                <div class="search-left-search">
                    <a-button
                    class="search-search-button"
                    shape="circle"
                    icon="search"
                    size="small"
                    @click="searchButton()"
                    />
                    <input
                    class="search-search-box"
                    type="text"
                    v-model="searchText"
                    v-on:keyup.enter="searchButton()"
                    placeholder="搜索组导图"
                    />
                    <div v-if="searchText != ''" class="cleariconfont search-right-icon" @click="clearSearchText()">&#xe605;</div>
                </div>
            </div>
      </div> -->
      <div v-if="mindFileObj != null" class="header-detail">
        <div class="header-detail-left">{{ $getStringObj.getString($Strings.Mind_Group_Mind_Mapping) }}{{ mindFileObj.totalNum }}({{ $getStringObj.getString($Strings.Current_Storage) }}{{ mindFileObj.storageUse }}/{{ mindFileObj.storageTotal }})</div>
        <div v-if=" mindFileObj.showUpgradeButton" @click="upgradeSave()" class="header-detail-button">
          <img src="../../../../assets/img/groupImg/upgrade.png" alt="">
          <span>
            {{ $getStringObj.getString($Strings.Mind_Mind_Upgrade_Storage_Space) }}
          </span>
        </div>
      </div>
      <div v-if="isShowSearchPage == false && mindFileObj != null &&  mindFileObj.path != '' " class="crumbs-show-box">
        <GroupMindCrumbs @backToThePreviousLevel="backToThePreviousLevel" :showPath="true"  :mindFileObj="mindFileObj" />
      </div>

      <div id="filepagebox" class="mind-file-list-box">
        <div id="filepageboxchild">
          <template v-if="gfilesArr != null && gfilesArr.length > 0 ">
            <div v-for="(item,index) in gfilesArr" :key="index" @mouseover.stop="moveMenuIndex(index)" @click="openMind(item)" class="clearfix">
              <div class="mind-file-list-box-each clearfix">
                <div class="mind-each-center">
                  <div class="mind-each-center-title">
                    <div class="mind-each-center-title-content">
                      <div class="title-content-name">
                        {{ item.name }}
                      </div>
                      <!-- <div v-if="item.isTop" class="mind-each-center-title-stick">
                        {{ $getStringObj.getString($Strings.Mind_Group_Stick) }}
                      </div> -->
                    </div>
                    <div class="mind-each-center-title-sub-content">
                      {{ item.subtitle }}
                    </div>
                  </div>
                </div>
                <img class="mind-each-left" v-if="item.type == 0" src="../../../../assets/img/groupImg/file-icon.svg" alt="">
                <img class="mind-each-left mind-each-left-style" v-if="item.type == 1" src="../../../../assets/img/user/plate_plan_4.jpg"
                    v-real-img="httpImageToPrefix(item.cover)" alt="">
                
                <div class="mind-each-right">
                  <div class="date-detail">
                    {{ timestampToTimeDHM(item.modifyTime) }}
                    <img v-if="item.isTop" style="margin-top: -4px;" src="../../../../assets/img/groupImg/setTop.svg" alt="">
                  </div>
                  <div @mouseover.stop="movEachMenu($event,true,item)" @mouseout.stop="movEachMenu($event,false,item)" class="mind-file-menu-icon">
                    <img class="icon" src="../../../../assets/img/groupImg/more.svg" alt="">
                  </div>
                  
                </div>
              </div>
              <div v-if="index != gfilesArr.length - 1" class="each-box-bottom-line" ></div>
            </div>
          </template>
          <template v-else>
              <img class="null-message" src="../../../../assets/img/groupImg/nullMind.svg"  alt="">
              <div  class="null-message-text">{{ $getStringObj.getString($Strings.Group_Mind_No_Documents) }}</div>
              <div style="text-align: center;margin-top: 10px;">
              <div @click="showAllMindChange()" class="null-mind-right-box-button">
                  {{ $getStringObj.getString($Strings.Mind_Group_Immediately_Create) }}
              </div>
              </div>
          </template>
        </div>
      </div>
      <div @mouseover.stop="moveMenu($event,true)" @mouseout.stop="moveMenu($event,false)" :style="{right: fullWidth > 1400 ? (fullWidth -1400)/2 + 20 + 'px': 20 + 'px',bottom: 20 + 'px'}" class="button-establish-menu "  >
          <img src="../../../../assets/img/groupImg/add-check.png" alt="">
      </div>

      <MenuListBox :showMenu="showMenu" :showData="menuList" :showIndex="showIndex" :fullHeight="fullHeight" :menuPoint="menuPoint" :pointObj="pointObj" @checkMenuItem="checkMenuItem"  />

      <GroupMindInput :groupDataDetail="groupDataDetail" :tab="0" :folderId="folderId"
       :show="showTag" @hiddenTap="hiddenTap" />

      <GroupFolderNameChange :groupDataDetail="groupDataDetail" :tab="0" :folderId="folderId" :show="showChangName" :moveItem="moveItem" @hiddenChangName="hiddenChangName" />

      <MyMoveFileList
        :moveFile="moveFile"
        :groupDataDetail="groupDataDetail"
        :folderId="folderId"
        @hideMoveFile="hideMoveFile"
      />

      <GroupMoveFile         
        :moveFile="moveGroupFile"
        :groupDataDetail="groupDataDetail"
        :moveItem="moveItem"
        :tab="0"
        @hideMoveFile="hideGroupMoveFile" />

      <GroupCreatedMind :moveFile="showCreatedMind"
        :groupDataDetail="groupDataDetail"
        :folderId="folderId"
        @hideMoveFile="hideCreatedBox" />
    <!-- 举报模态框 -->
    <a-modal
      class="report-crisps-model"
      :centered="true"
      v-model="reportCrispsVisible"
      :title="$getStringObj.getString($Strings.Mind_Storm_Report)"
      @ok="reportCrispsHandleOk"
      :cancelText="$getStringObj.getString($Strings.Global_Cancel)"
      :okText="$getStringObj.getString($Strings.Global_Ok)"
    >
      <div class="report-crisps-style-box">
        <div
          class="report-crisps-style"
          v-for="(item, index) in reportCrispsContentList"
          :key="index"
          @click="reportCrispsClick(index)"
        >
          <div class="report-crisps-left">
            {{ $getStringObj.getString(item) }}
          </div>
          <div class="report-crisps-right">
            <div
              class="report-crisps-defualt-click"
              :class="
                reportCrispsContentListNumber == index
                  ? 'report-defualt-click'
                  : ''
              "
            ></div>
            <img
              v-if="reportCrispsContentListNumber == index"
              src="../../../../../static/img/userData/crisps-reporet-click.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { postGroupListGfiles, postGroupSearchGfiles, 
postGroupDeleteGfile, postGroupSetTopGfile, 
postGroupSaveGmap, postGroupUnsetTopGfile, 
postGroupReportGfile, 
postGroupUploadGfile} from "../../../../common/netWork/group_api";
import { mapMutations } from "vuex";
import GroupMindCrumbs from "./groupMindFileChild/GroupMindCrumbs";
import MenuListBox from "../menuListBox/MenuListBox";
import timestampToTime from "../../../../utils/timestampToTime"
import httpImageToPrefix from '../../../../utils/httpImageToPrefix';
import GroupMindInput from './groupMindFileChild/GroupMindInput';
import GroupFolderNameChange from './groupMindFileChild/GroupFolderNameChange';
import MyMoveFileList from './groupMindFileChild/MyMoveFileList';
import GroupMoveFile from './groupMindFileChild/GroupMoveFile';
import GroupCreatedMind from "./groupMindFileChild/GroupCreatedMind"

if ( typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0 ) {
  var {ipcRenderer} = window.require('electron')
  // var ipcRenderer = window.ipcRenderer
}
export default {
    props: [
        "groupDataDetail",
        "fullWidth",
    ],
    components: {
      GroupMindCrumbs,
      MenuListBox,
      GroupMindInput,
      MyMoveFileList,
      GroupFolderNameChange,
      GroupMoveFile,
      GroupCreatedMind
    },

    data() {
        return {
          iconRightSpace: 35, //图标距离右边边距
          menuList: [
            '上传文档',
            '创建文件夹'
          ],
          pointObj: {

          },
          menuPoint: {
            val1: 'top',
            val2: 'left', 
          },
          showMenu: false,  //是否展示菜单
          showTag: false,  //是否展示创建文件夹框
          nowFileObj: null,  //当前所处文件夹的信息,根目录为空
          moveFile: false, //是否展示添加文件模态框
          moveData: [], //移动文件夹的数据。里面有所有的子文件夹。
          isElectron: false, //是否是electron环境
          isScroll: true, //是否可以滚动
          searchText: '', //搜索内容
          groupDataDetailOld: null, //旧的组详情
          mindFileObj: null, //导图列表数据
          page: 1, //当前所在页面
          folderId: "", //当前所在文件夹的id
          gfilesArr: [], //群组文件夹内文件的数组。
          isShowSearchPage: false, //是否展示搜索的页面
          moveItem: null, //当前移动的数据
          showChangName: false, //是否展示改变文件夹名称模态框
          reportCrispsVisible: false, //举报面板
          reportCrispsContentList: [    //举报理由列表
            this.$Strings.Mind_Storm_Pornographic, //色情低俗
            this.$Strings.Mind_Storm_Advertising_Harassment, //广告骚扰
            this.$Strings.Mind_Storm_Political_Religion, //政治宗教
            this.$Strings.Mind_Storm_Infringement, //侵权（肖像、诽谤、抄袭、冒用）
            this.$Strings.Mind_Storm_Prohibited_Content, //违禁内容
            this.$Strings.Mind_Storm_Spread_Rumors, //造谣传谣
          ],
          reportCrispsContentListNumber: 0, //举报选项
          reportCrispsClickContent: this.$Strings.Mind_Storm_Pornographic, //举报选中的内容
          moveGroupFile: false, //是否展示移动组文件模态框
          showCreatedMind: false, //是否展示创建导图文件夹。
          fullHeight: document.documentElement.clientHeight,
          showIndex:-1,
        }
    },
    created() {
      this.isElectron = this.$tools.isElectron();
    },
    mounted(){
      this.get_bodyWidth();
    },
    updated() {
    },
    destroyed() {
      let scrollDom = document.getElementById("filepagebox");
      if (scrollDom != undefined) {
          scrollDom.removeEventListener("scroll", this.scrollMoreData); //解除绑定
      }
    },
    methods: {
        ...mapMutations([
            "setgroupMenu",
            "setgroupCreatedMenu",
            "reloadStorageSpace",
        ]),
        get_bodyWidth() {
          //动态获取浏览器高度
          const that = this;
          window.addEventListener("resize", () => {
            that.fullHeight = document.documentElement.clientHeight;
          });
        },
        upgradeSave() {
          this.$emit('upgradeSave',true)
        },

        backToThePreviousLevel(obj) {
          if (obj.back == true) {
            this.folderId = this.mindFileObj.upperId;
            this.refreshMindList();
          }
        },

        scrollMoreData() {
            //滚动事件
            let scrollDom = document.getElementById("filepagebox");
            let child = document.getElementById("filepageboxchild");
            if( scrollDom == null || child == null) {
                return;
            }
            const scrollTopHeight = scrollDom.scrollTop ; //滚动高度
            const scrollHeight = scrollDom.clientHeight; //父盒子高度
            const childHeight = child.offsetHeight ; //网页可见区域高(包括边线的宽)
            if (
                scrollTopHeight + scrollHeight >= childHeight &&
                this.isScroll
            ) {
                this.isScroll = false;
                this.scrollPullList()
            }
        },

        openMind(item) {
          if (item.type == 0) {
            this.folderId = item.id;
            this.refreshMindList();
            return;
          } else if (item.type == 1) {

            if (this.isElectron) {
              ipcRenderer.send("openCalendarWindow", "/gmap?id=" + item.gmapId);
            } else {
              const newWindow = window.open();
              const routerData = this.$router.resolve({
                path: "/gmap",
                query: {
                  id: item.gmapId,
                },
              });
              newWindow.location = routerData.href;
            }
          }
        },

        hideCreatedBox(val) { //关闭创建导图的弹框
          if (val.show == false) {
            this.showCreatedMind = false;
            if (val.isCreated == true) {
              this.refreshMindList();
            }
          }
        },

        hideGroupMoveFile(val) {   //关闭移动文件模态框
          if (val.show == false) {
            this.moveGroupFile = false;
            if ( val.move == 1 ) {
              this.refreshMindList();
            }
          }
        },

        hideMoveFile(val) {   //关闭导入模态框
          if (val.show == false) {
            this.moveFile = false;
            if ( val.move == 1 ) {
              this.refreshMindList();
            }
          }
        },

        hiddenTap(val) {    //隐藏创建文件夹输入框
          this.showTag =false;
          if (val.show == false && val.confrim == true) {
            this.refreshMindList();
          }
        },

        hiddenChangName(val) {
          this.showChangName = false;
          if (val.show == false && val.confrim == true) {
            this.refreshMindList();
          }
        },
        //点击立即创建
        showAllMindChange(){
          this.showCreatedMind = true;
        },
        checkMenuItem (val) {   //点击了菜单中的选项
          if (val.type == 'addFile') {
            if (val.name == "创建文件夹") {
              this.showTag = true;
            } else if (val.name == '创建导图') {
              this.showCreatedMind = true;
            } else if (val.name == '导入导图') {
              this.moveFile = true;
            }

          } else if ( val.type == 'eachMenu' ) {
            if (val.name == this.$getStringObj.getString(this.$Strings.Mind_Group_Stick)) {
              let  setTopGfile = {
                gfileId: this.moveItem.id
              }
              this.setGroupSetTopGfile(setTopGfile).then(res => {
                return this.refreshMindList();
              })
              
            } else if (val.name == this.$getStringObj.getString(this.$Strings.Mind_Group_Cancel_The_Top)) {
              let  cancelSetTopGfile = {
                gfileId: this.moveItem.id
              }
              this.cancelSetGroupSetTopGfile(cancelSetTopGfile).then(res => {
                return this.refreshMindList();
              })
            } else if (val.name == '保存') {
                let saveObj = {
                  gmapId: this.moveItem.gmapId
                }
                this.saveMind(saveObj).then(res => {
                    this.$message.success('保存成功')
                })
            } else if (val.name == '重命名') {
                this.showChangName = true;
            } else if (val.name == '删除') {
              this.removeFile();
            } else if (val.name == '移动') {
              // this.reportCrisps();
              this.moveGroupFile = true;
            } else if (val.name == '举报') {
              this.reportCrisps();
            }
          }
        },

        //打开举报面板
        reportCrisps() {
          this.reportCrispsVisible = true;
        },

        //举报
        reportCrispsHandleOk() {
          var gfileId  = this.moveItem.id;
          var reason = this.$getStringObj.getString(this.reportCrispsClickContent);
          postGroupReportGfile(
            { gfileId, reason },
            (res) => {
              this.$message.success(this.$getStringObj.getString(this.$Strings.Mind_Storm_Report_Successful));
              this.reportCrispsVisible = false;
            },
            (error) => {
              this.$message.error(error.desc);
            }
          );
        },

                    //举报选项
        reportCrispsClick(index) {
          this.reportCrispsContentListNumber = index;
          this.reportCrispsClickContent = this.reportCrispsContentList[index];
        },

        //删除文件或者文件夹
        removeFile() {
          let that = this;
          this.$confirm({
            title: "删除?",
            content: "确定要删除吗？",
            centered: true,
            style: {
              borderRadius: "20px",
            },
            onOk() {
              let deletObj = {groupId:that.groupDataDetail.id, tab: 0, gfileId: that.moveItem.id }
              that.setGroupDeleteGfile(deletObj).then(res => {
                that.reloadStorageSpace(true);
                return that.refreshMindList();
              })
            },
            onCancel() {},
          });
        },

        httpImageToPrefix(src) {
            return httpImageToPrefix(src)
        },

        timestampToTimeDHM(timestamp) {
            return timestampToTime.formatTime(timestamp);
        },

        movEachMenu(e, show, item) {  //鼠标放在了文件夹菜单按钮
            this.menuList = [
                {
                    name: this.$getStringObj.getString(this.$Strings.Mind_Group_Stick),
                },
                {
                    name: '保存',
                },
                {
                    name: '重命名',
                },
                {
                    name: '删除',
                },
                {
                    name: '移动'
                },
                {
                    name: '举报'
                },
            ]

            if ( item.type == 0 ) {  //如果是文件夹
               this.menuList.splice(1,1);
            } 

            if (item.isTop) {
              this.menuList.splice(0,1,{name: this.$getStringObj.getString(this.$Strings.Mind_Group_Cancel_The_Top)});
            }

            this.pointObj = e.target.getBoundingClientRect();

            this.menuPoint = {
              val1: 'left',
              val2: 'bottom', 
              type: 'eachMenu'
            }
            if (show) {
                this.showMenu = true;
            } else {
                this.showMenu = false
            }

            this.moveItem = item;
        },
        moveMenu(e,show) {  //鼠标放在了添加菜单
            this.menuList = [
                {
                    name: '创建导图',
                },
                {
                    name: '导入导图',
                },
                {
                    name: '创建文件夹',
                }
            ]
            this.pointObj = e.target.getBoundingClientRect();

            this.menuPoint = {
              val1: 'top',
              val2: 'left', 
              type: 'addFile'
            }
            if (show) {
                this.showMenu = true;
            } else {
                this.showMenu = false;
            }
        },
        moveMenuIndex(index){
          this.showIndex = index;
        },

        searchButton() {  //点击搜索按钮
          let obj = {
            groupId: this.groupDataDetail.id,
            query: this.searchText,
            tab: 0,
            parentId: this.folderId,
          }
          this.pullGroupSearchGfiles(obj).then(res => {

          })
        },
        clearSearchText() {   //清空搜索输入框
            this.searchText = "";
        },

        refreshMindList() {   //刷新当前页面数据
          this.pullMindList({ groupId: this.groupDataDetail.id, tab: 0, parentId: this.folderId, page: 1 }).then(res=>{
            if (res !=  null) {
              this.mindFileObj = res
              this.page = 1;
              this.gfilesArr = res.gfiles;
            }
            this.$nextTick(() => {
                let scrollDom = document.getElementById("filepagebox");
                if (scrollDom != undefined) {
                    scrollDom.removeEventListener("scroll", this.scrollMoreData); //解除绑定
                    scrollDom.addEventListener("scroll", this.scrollMoreData, false); //添加绑定
                }
            });
          })
        },

        scrollPullList() {
            this.page;
            let obj = { groupId: this.groupDataDetail.id, tab: 0, parentId: this.folderId, page: this.page + 1 };
            this.pullMindList(obj).then(res => {

                if (res !=  null && res.gfiles != null && res.gfiles.length > 0) {
                  this.page++;
                  this.gfilesArr = this.gfilesArr.concat(res.gfiles);
                }

            }).finally(res=> {
                this.isScroll = true;
            })
        },

        pullGroupSearchGfiles(obj) {
            return new Promise((resolve, reject) => {
                postGroupSearchGfiles(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

        pullMindList(obj) {
            return new Promise((resolve, reject) => {
                postGroupListGfiles(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

        setGroupDeleteGfile(obj) {
            return new Promise((resolve, reject) => {
                postGroupDeleteGfile(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

        setGroupSetTopGfile(obj) {
             return new Promise((resolve, reject) => {
                postGroupSetTopGfile(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

        cancelSetGroupSetTopGfile(obj) {
             return new Promise((resolve, reject) => {
                postGroupUnsetTopGfile(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

        saveMind(obj) {
            return new Promise((resolve, reject) => {
              postGroupSaveGmap(
              obj,
              (res) => {
                  resolve(res);
              },
              (error) => {
                  this.$message.error(error.desc);
                  reject(error);
              }
              );
          });
        },
        

    },
    watch: {
      groupDataDetail: {
        handler(newVal,oldVal) {
          //避免再次点击这个群组的文件，然后群组数据就回到首页了
          if ( this.groupDataDetailOld != null && this.groupDataDetailOld.id != newVal.id) {
              this.mindFileObj = null; //导图列表数据
              this.page = 1; //当前所在页面
              this.folderId = "", //当前所在文件夹的id
              this.gfilesArr = []; //群组文件夹内文件的数组。
          }
          this.groupDataDetailOld = newVal;
          this.refreshMindList();

        },
        deep: true,
        immediate: true,
      }
    }

}
</script>

<style lang="less" scoped>
.null-mind-right-box-button {
                height: 32px;
                line-height: 32px;
                display: inline-block;
                border-radius: 16px;
                font-size: 16px;
                color: #fff;
                padding: 0 16px;
                background-color: #FD492B;
                cursor: pointer;
              }
  .mind-file-page {
    // padding: 0 20px;
    .search-header-input {
      padding: 0 20px;
      // width: 574px;
      width: 100%;
      height: 32px;
      margin: 0 auto;
      margin-top: 20px;
      margin-bottom: 20px;
      .search-left-search {
          width: 100%;
          height: 32px;
          margin-left: 0px;
          position: relative;

          .search-right-icon {
              width: 30px;
              height: 30px;
              line-height: 32px;
              color: #bbb;
              font-size: 18px;
              position: absolute;
              right: 0;
              top: 0;
              cursor: pointer;
              &:hover {
                  color: #ff7354;
              }
              &:active {
                  color: #d6301a;
              }
          }

          .search-search-button {
              position: absolute;
              margin-left: 10px;
              margin-top: 3px;
              border: 0px;
              background: none;
          }

          .ant-btn:hover,
          .ant-btn:focus {
              background: #fd492b;
              color: #fff;
              opacity: 1;
              border: none;
          }

          .search-search-box {
              width: 100%;
              border-radius: 15px;
              border: transparent;
              background: #F0F2F8;
              height: 30px;
              padding-left: 35px;
          }

          .search-search-box:focus {
              outline: none;
          }
      }
    }

    .header-detail {
      display: flex;
      align-items: center;
      padding: 32px 20px;
      padding-bottom: 8px;
      .header-detail-left {
        color: #999;
        font-size: 12px;
      }
      .header-detail-button {
        margin-left: 20px;
        color: #fff;
        background-color: #FD492B;
        font-size: 12px;
        height: 24px;
        line-height: 24px;
        padding: 0 12px;
        border-radius: 12px;
        cursor: pointer;
        img {
          margin-right: 4px;
          margin-bottom: 4px;
        }
      }
    }

    .crumbs-show-box {
      padding-bottom: 8px;
    }

    .mind-file-list-box {
       padding-bottom: 150px;
       height: calc( 100vh - 260px);
       overflow-y: auto;
      .mind-file-list-box-each {
        height: 70px;
        padding: 0 20px;
        padding-top: 20px;
        cursor: pointer;
        .mind-each-left {
          width: 56px;
          height: 56px;
          float: left;
          margin-left: -100%;
        }
        .mind-each-left-style {
          border: 1px solid #eee;
          border-radius: 4px;
        }
        .mind-each-center {
          width: 100%;
          float: left;
          .mind-each-center-title {
            color: #333;
            font-size: 16px;
            height: 30px;
            .mind-each-center-title-content {
              padding-left: 63px;
              display: flex;
              .title-content-name {
                max-width: calc(100% - 138px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .mind-each-center-title-stick {
                background-color:#EEEEEE;
                color: #999999;
                font-size: 12px;
                padding: 0 4px;
                height: 19px;
                margin-left: 20px;
              }
            }
            .mind-each-center-title-sub-content {
              font-size: 12px;
              margin-top: 4px;
              padding-left: 63px;
              padding-right: 107px;
              color: #999999;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
        .mind-each-right {
          float: right;
          width: 100px;
          height: 56px;
          margin-left: -100px;
          .date-detail {
            font-size: 12px;
            color: #999;
            text-align: right;
          }
          .mind-file-menu-icon {
            float: right;
            padding: 2px 10px;

            .icon {
              width: 14px;
              height: 14px;
            }
          }
        }
      }

      .each-box-bottom-line {
        height: 2px;
        background-color: #f6f6f6;
        width: calc(100% - 102px);
        margin-right: 20px;
        
        margin-top: 10px;
        float: right;
      }
      &::-webkit-scrollbar {
          width: 6px;
      }
      &::-webkit-scrollbar-track {
          background: rgb(239, 239, 239);
          border-radius: 2px;
      }
      &::-webkit-scrollbar-thumb {
          background: #bfbfbf;
          border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb:hover {
          background: #bfbfbf;
      }
    }

    .button-establish-menu {
        position: fixed;
        right: 50px;
        bottom: 50px;
        width: 78px;
        height: 78px;
        
        img {
            position: absolute;
            width: 78px;
            height: 78px;
            transition: all .5s;
        }
        .check-establish-button {
            transform: rotate(-45deg);
        }
    }

    .null-message {
        display: block;
        margin: 0 auto;
        margin-top: 163px;

    }
    .null-message-text {
        margin-top: 10px;
        color: #999;
        font-size: 14px;
        text-align:center;
    }
  }
</style>