<template>

  <div class="group-tag-page" @click.stop="cklickModal(false)" v-show="tagModalShow">

      <div @click.stop="cklickModal(true)" class="group-tag-page-center">
          <div class="file-load-title">文件上传进度</div>
          <div class="load-content">
                <a-progress :strokeWidth="12" stroke-linecap="square" :percent="percent" />
                <div class="load-content-text">
                    文件{{ loaded }} / {{ total }}
                </div>
          </div>
      </div>
  </div>
</template>

<script>
import { postGroupNewGdir } from "../../../../../common/netWork/group_api";
export default {
    props: {
        show:{
            type: Boolean,
            defualt() {
                return {};
            },
        },
        data:{
            type: Object,
            defualt() {
                return {};
            },
        },

  },
    data() {
        return {
            tagModalShow: false,
            percent: 0,
            loaded: 0,
            total: 0,
        }
    },
    methods: {
        cklickModal(val) {
            // this.tagModalShow = val;
            // if (val == false) { //当隐藏模态框时
            //     this.hiddenTap({show:false, confrim: this.confrim, groupTagName: this.trim(this.groupTagName)})
            //     this.groupTagName = ''
            //     this.warning = ''
            // }
        },
        // hiddenTap(obj) {
        //     this.$emit("hiddenTap", obj);
        // },
        // affirm() {
        //     if (this.trim(this.groupTagName) == '') {
        //         this.warning = '文件夹名称不能为空'  //this.$getStringObj.getString(this.$Strings.Mind_Group_The_Content_Cannot_Be_Empty)
        //         return
        //     }
        //     this.confrim = true;
        //     // let parentId =  nowFileObj 
        //     let obj = {
        //         name: this.trim(this.groupTagName),
        //         tab: this.tab,
        //         parentId: this.folderId == null ? '' : this.folderId ,
        //         groupId: this.groupDataDetail.id
        //     }
        //     this.pullGroupTag(obj).then(res=> {
        //         this.cklickModal(false);
        //         this.confrim = false;
        //     });
        // },
        // cancel() {
        //     this.confrim = false;
        //     this.cklickModal(false);
        // },
        // trim(str) { //判断有首尾空格去除空格
        //     return str.replace(/^\s+|\s+$/g, '');
        // },
        // inputArea() {   //不允许输入或黏贴超过20个字符
        //     this.warning =''
        //     if (this.groupTagName.length > 20){
        //         this.groupTagName = this.groupTagName.slice(0,20);
        //     }
        // },
        // pullGroupTag(obj) {
        //     return new Promise((resolve, reject) => {
        //         postGroupNewGdir(
        //         obj,
        //         (res) => {
        //             resolve(res);
        //         },
        //         (error) => {
        //             this.$message.error(error.desc);
        //             reject(error);
        //         }
        //         );
        //     });
        // },
    },
    watch: {
        show: {
            handler(newval, olval) {
                this.tagModalShow = newval
            },
            deep: true,
            immediate: true
        },
        data: {
            handler(newval, olval) {
                this.percent = newval.percent;
                this.loaded = newval.loaded;
                this.total = newval.total;

                // this.percent = this.percent.slice(0,-1)
            },
            deep: true,
            immediate: true
        },
    }
}
</script>

<style lang="less" scoped>
    .group-tag-page {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,.3);
        .group-tag-page-center {
            position: absolute;
            width: 300px;
            min-height: 150px;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background-color: #fff;
            border-radius: 4px;
            .file-load-title {
                padding-top: 20px;
                text-align: center;
                font-size: 16px;
            }
            .load-content {
                padding: 20px 20px;
            }

            .load-content-text {
                text-align: center;
                padding-top: 20px;
            }

            // .group-tag-detail-list {
            //     width: 230px;
            //     font-size: 14px;
            //     line-height: 40px;
            //     color: #333333;
            //     margin: 0 auto;
            //     margin-bottom: 20px;
            //     .group-tag-detail-name {
            //         // margin-right: 20px;
            //         text-align: center;
            //         font-size: 16px;
            //         margin-bottom: 10px;
            //     }
            //     .group-tag-detail-input {
            //         width: 100%;
            //         // width: 230px;
            //         height: 38px;
            //         padding-left: 10px;
            //         border: 1px solid #E2E2E2;
            //         background-color: #F0F2F8;
            //         outline: none;
            //         border-radius: 2px;
            //         font-size: 12px;
            //     }
            //     .tag-warning {
            //         // text-align: center;
            //         color: #FD492B;
            //     }
            // }
            // .group-tap-footer {
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     margin-bottom: 20px;
            //     .group-tap-footer-button {
            //         padding: 0 12px;
            //         margin: 0 20px;
            //         height: 24px;
            //         text-align: center;
            //         line-height: 24px;
            //         border-radius: 12px;
            //         font-size: 14px;
            //         color: #333;
            //         background-color: #F0F2F8;
            //     }
            //     .group-tap-footer-button:hover {
            //         background-color: #FD492B;
            //         color: #fff;
            //     }
            // }



        }
    }
</style>