import { render, staticRenderFns } from "./GroupMoveFile.vue?vue&type=template&id=46609380&scoped=true"
import script from "./GroupMoveFile.vue?vue&type=script&lang=js"
export * from "./GroupMoveFile.vue?vue&type=script&lang=js"
import style0 from "./GroupMoveFile.vue?vue&type=style&index=0&id=46609380&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46609380",
  null
  
)

export default component.exports