import { render, staticRenderFns } from "./AlterModal.vue?vue&type=template&id=6af1bc63&scoped=true"
import script from "./AlterModal.vue?vue&type=script&lang=js"
export * from "./AlterModal.vue?vue&type=script&lang=js"
import style0 from "./AlterModal.vue?vue&type=style&index=0&id=6af1bc63&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6af1bc63",
  null
  
)

export default component.exports