<template>
  <div class="group-setting-administrator">
    <div class="group-setting-administrator-header-box">
      <div class="administrator-header-left">
        <span class="setting-click-button" v-if="this.isDeleGroupAdmin" @click="changeDeleGroupAdminShow(false,'no')">
          {{ $getStringObj.getString($Strings.Mind_Group_Cancel) }}
        </span>
      </div>
      <div class="administrator-header-main">
          {{ $getStringObj.getString($Strings.Mind_Group_Administrator) }}
      </div>
      <div class="administrator-header-right">
        <span class="setting-click-button" v-if="this.isDeleGroupAdmin" @click="changeDeleGroupAdminShow(false,'ok')">
          {{ $getStringObj.getString($Strings.Mind_Group_Accomplish) }}
        </span>
        <span class="setting-click-button"  v-else @click="changeDeleGroupAdminShow(true,'update')">
          {{ $getStringObj.getString($Strings.Mind_Group_Compile) }}
        </span>
      </div>
    </div>
    <div v-if="groupDataDetail != null" class="group-admin-list-content">
      <div class="group-admin-list-content-children">
          <div class="admin-title">{{ $getStringObj.getString($Strings.Mind_Group_Compile) }} 群主</div>
          <div class="group-admin-list-box">
            <div v-for="(item,index) in groupAdminList" :key="index">
              <div v-if="item.type == 2" class="group-admin-list-each">
                  <!-- <img class="admin-delet" src="../../../../assets/img/groupImg/setting-delet-admin.png" alt=""> -->
                  <!-- <div
                    class="group-admin-cover"
                    :style="{
                            display: 'block',
                            width: 28 + 'px',
                            height: 28 + 'px',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                            overflow: 'hidden',
                            backgroundImage: 'url('+ httpImageToPrefix(item.avatar) +')',
                        }"
                  ></div> -->
                  <div :style="{position: 'relative', height: 44 + 'px', width: 44 + 'px', margin: '0 8px 0 0', }" >
                      <img 
                          v-if="item.isMember"
                          :style="{
                              position: 'absolute',
                              zIndex: 1,
                              left: 50 + '%',
                              transform: 'translate(-50%,0)',
                              top: -13 + 'px',
                              width: 24 + 'px',
                              height: 16 + 'px',
                          }"
                          src="../../../../assets/img/groupImg/crown.svg"
                          alt
                          />
                      <div 
                          :style="{
                                  position: 'absolute',
                                  left: '50%',
                                  bottom: '1px',
                                  transform: 'translate(-50%,0)',
                                  width: 44 + 'px',
                                  height: 44 + 'px',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center center',
                                  overflow: 'hidden',
                                  borderRadius: '50%',
                                  backgroundImage: 'url('+ httpImageToPrefix(item.avatar) +')',
                              }"
                      ></div>
                  </div>
                  
                            
                  <div class="admin-name">
                    {{ item.username }}
                  </div>
              </div>
            </div>
          </div>
      </div>
      <div class="group-admin-list-content-children">
          <div  class="admin-title">{{ $getStringObj.getString($Strings.Mind_Group_Administrator) }}</div>
          <div class="group-admin-list-box">
            <div v-for="(item,index) in groupAdminList" :key="index">
              <div v-if="item.type != 2 && !item.isDelet" @click="checkItem(item,index)" class="group-admin-list-each member-pointer">
                  <img v-if="isDeleGroupAdmin " class="admin-delet" src="../../../../assets/img/groupImg/setting-delet-admin.png" alt="">
                  <div
                    class="group-admin-cover"
                    :style="{
                            display: 'block',
                            width: 44 + 'px',
                            height: 44 + 'px',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                            overflow: 'hidden',
                            backgroundImage: 'url('+ httpImageToPrefix(item.avatar) +')',
                        }"
                  ></div>
                            
                  <div class="admin-name">
                    {{ item.username }}
                  </div>
              </div>
            </div>
          </div>
      </div>
      <div class="group-add-admin-big-box">
        <img @click="addAddmin()" src="../../../../assets/img/groupImg/setting-add-admin.png" alt="">
        <div @click="addAddmin()" class="group-add-text">
          {{ $getStringObj.getString($Strings.Mind_Group_Add_As_Administrator) }}
        </div>
      </div>
    </div>
    <GroupAddAdmin     
        :show="showModal"
        :groupDataDetail="groupDataDetail"
        @hidden="hidden"
    />
  </div>
</template>

<script>

import { postGroupAdmins, postGroupDeleteGadmins } from "../../../../common/netWork/group_api";
import httpImageToPrefix from '../../../../utils/httpImageToPrefix';
import { mapMutations } from "vuex";
import GroupAddAdmin from "./GroupAddAdmin";
export default {
    components: {
      GroupAddAdmin
    },
    data() {
        return {
          groupDataDetail: null, //组详情
          groupAdminList: [], //共享组管理员列表
          showModal: false, //是否展示模态框
          isDeleGroupAdmin: false, // 是否展示删除管理员按钮
          removeAdminList: [], //要删除的管理员数组
        }
    },
    created() {
      this.refreshList();
    },
    mounted() {

    },
    destroyed() {
    },
    methods: {
        ...mapMutations([
            "setRefreshMyGroupList"
        ]),
        changeDeleGroupAdminShow(val, type) {
            this.isDeleGroupAdmin = val
            if (type == 'no'){
              this.removeAdminList = [];
            } else if (type == 'ok') {
              this.removeGroupAdmin({ groupId: this.groupDataDetail.id, userIds: this.removeAdminList }).then(res => {
                 this.removeAdminList = [];
                 this.setRefreshMyGroupList();
              })
              // this.removeAdminList = [];
            } else if (type == 'update') {

            }

        },
        hidden(obj) {
          if (obj.show == false){
              this.showModal = false
              this.setRefreshMyGroupList()
              // this.setRefreshMyGroupList()
              // this.setGroupDataDetail(this.checkGroupDataDetail);
          }
        },
        addAddmin() {
          this.showModal = true;
        },

        refreshList() {
          this.pullGroupAdmin({groupId: this.groupDataDetail.id}).then((res) => {
            this.groupAdminList = res.admins;
            if (this.groupAdminList != null && this.groupAdminList.length > 0) {
              for (let index = 0; index < this.groupAdminList.length; index++) {
                //设置所有组成员默认为不删除。
                this.groupAdminList[index].isDelet = false;
              }
            }
          })
        },

        httpImageToPrefix(src) {    //拼接图片地址
           return httpImageToPrefix(src)
        },

        checkItem(item,index) {
          let point = this.removeAdminList.indexOf(item.id);
          this.groupAdminList[index].isDelet = !this.groupAdminList[index].isDelet;
          this.groupAdminList.splice(index, 1, this.groupAdminList[index]);
          if(point > -1) {
            this.removeAdminList.splice(point,1);
          } else {
            this.removeAdminList.push(item.id);
          }
          
        },

// postGroupDeleteGadmins
        removeGroupAdmin(obj){
            return new Promise((resolve, reject) => {
                postGroupDeleteGadmins(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                });
            });
        },

        pullGroupAdmin(obj) {
            return new Promise((resolve, reject) => {
                postGroupAdmins(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                });
            });
        },
    },
    watch: {
        "$store.state.groupDataDetail" : {
            handler(newVal,oldVal) {
                if (newVal != null) {
                  this.groupDataDetail = newVal
                  this.refreshList();
                }
            },
            immediate: true,
            deep: true
        }
    }
}
</script>

<style lang="less" >
.group-setting-administrator {
  .group-setting-administrator-header-box{
      display: flex;
      .administrator-header-left{
          flex: 0 0 100px;
          min-height: 72px;
          text-align: center;
          line-height: 72px;
          font-size: 12px;
      }

      .administrator-header-main{
          flex: 1;
          width: 100%;
          min-height: 72px;
          // background-color: #c32228;
          text-align: center;
          line-height: 72px;
          color: #333;
          font-size: 16px;
      }

      .administrator-header-right{
          flex: 0 0 100px;
          min-height: 72px;
          text-align: center;
          line-height: 72px;
          font-size: 12px;
          // background-color: #f90;
      }

      .setting-click-button {
        font-size: 14px;
        cursor: pointer;
      }

  }

  .group-admin-list-content {
    .group-admin-list-content-children {
      .admin-title {
        color: #999;
        font-size: 14px;
        padding-left: 20px;
        height: 32px;
        line-height: 32px;
        background-color: #f2f2f2;
      }
      .group-admin-list-box {
        .group-admin-list-each {
          display: flex;
          height: 75px;
          align-items: center;
          padding-left: 20px;
          
          .admin-delet {
            margin-right: 20px;
            width: 24px;
            height: 24px;
          }
          .group-admin-cover {
            margin-right: 8px;
            width: 28px;
            height: 28px;
          }
          .admin-name {
            font-size: 14px;
            color: #333;
          }

        }

        .member-pointer {
          cursor: pointer;
        }

      }
    }
    .group-add-admin-big-box {
      padding-left: 20px;
      padding-top: 10px;
      display: flex;
      align-items: center;
      img{
        cursor: pointer;
      }
      .group-add-text {
        cursor: pointer;
        padding-left: 13px;
      }
    }

  }

}


</style>