<template >
    <div class="group-tag-page-shera" @click.stop="cklickModal(false)" v-show="tagModalShow">
        <div class="group-tag-shera-box">
        <div class="group-tap-footer-shera-sava" @click.stop="affirmSave">
                <!-- <div  @click.stop="affirm(true)" class="group-tap-footer-button" style="cursor:pointer"> -->
                  <img class="group-tap-footer-shera-sava-img"  src="../../../assets/img/groupImg/shareSave.png" alt="">
                   <span class="group-tap-footer-shera-sava-span" >
                     {{ $getStringObj.getString($Strings.Mind_Group_Save) }}</span>
                <!-- </div> -->
        </div>
        <div class="group-tag-shera-close" @click.stop="cklickModal(false)">
                <a-icon type="close" :style="{ fontSize: '16px' }" />
        </div>
        <div v-if="this.shareList!=null" class="group-tag-page-center-shera" onClick="event.cancelBubble = true">
            
            <div class="group-tag-shera-list">
                <div class="group-tag-shera-name" >
                   {{shareList.name}}
                </div>
                <div class="group-tag-shera-desc" >
                    {{shareList.desc}}
                </div>
            </div>
            <div class="group-tag-shera-group-Id">
              {{ $getStringObj.getString($Strings.Mind_Group_Group_Number) }}:{{shareList.code}}
            </div>
            <div class="group-tag-shera-head-portrait">
                <img class="group-shera-img" style="width: 88px;height: 88px;" :src="this.imgSRC"/>
            </div>
            <div class="group-tag-shera-label">
              <div class="group-tag-shera-label-list" v-for="(lableList,index) in this.shareList.tags" :key="index"
               :style="index==0?{ marginLeft: '0px' }:''">
                  {{lableList}}
              </div>
            </div >
            <div class="group-tag-shera-rectangular-box">
                <div class="group-tag-shera-rectangular-box-qr-code">
                <vue-qr
                    :logoSrc="logoSrc"
                    :logoScale="0.25"
                    :text="this.shareList.url"
                    :size="135"
                    :correctLevel="0"
                    :margin="0"
                ></vue-qr>
                </div>
            </div>
            <div class="group-tag-shera-mind">
                {{ $getStringObj.getString($Strings.Mind_Edit_Use) }}{{ $getStringObj.getString($Strings.Mind_share_scan_code) }}
                {{ $getStringObj.getString($Strings.Mind_Group_Join) }}{{ $getStringObj.getString($Strings.Mind_Edit_Page_Sharing_Group) }}
            </div>
            <div class="group-tag-shera-about-app-name">
                <img class="group-tag-shera-about-app-name-img" src="../../../assets/img/groupImg/qrCodeLog.png" alt="">
                <span class="group-tag-shera-about-app-name-span">
                    {{ $getStringObj.getString($Strings.About_App_Name) }}
                </span>
            </div>
      </div>
      </div>
  </div>
</template>

<script>
import vueQr from "vue-qr";
import html2canvas from "html2canvas"
import imageToStyle from '../../../utils/ImageToolkit';
import httpImageToPrefix from '../../../utils/httpImageToPrefix';

export default {
     props: [
        "show",
    ],
    data() {
        return {
            tagModalShow: false,
            confrim: false,
            shareList:null,
            logoSrc: require("../../../assets/img/groupImg/qrCodeLog.png"),
            imgSRC: null,
        }
    },
    components: {
        vueQr,
        html2canvas,
    },
    methods: {
        imgBase(){
            // this.imgSRC=httpImageToPrefix(this.shareList.cover);
            new imageToStyle().webToBase64(httpImageToPrefix(this.shareList.cover)).then(imageBase64 => {
//                 this.photo.setAttribute("src", imageBase64)
                this.imgSRC=imageBase64;
            })

        },
        cklickModal(val) {
            if (val == false) { //当隐藏模态框时
                this.hiddenTap({show:false, confrim: this.confrim})
            }
        },
        hiddenTap(obj) {
            this.$emit("hiddenShare", obj);
        },
        affirmSave() {
            var downloadName = this.shareList.name;
            
            var canvas2 = document.createElement("canvas");
            let _canvas = document.querySelector('.group-tag-page-center-shera');
            // let img = document.querySelector('.group-shera-img');
            var w = parseInt(window.getComputedStyle(_canvas).width);
            var h = parseInt(window.getComputedStyle(_canvas).height);

            canvas2.width = w * 2;
            canvas2.height = h * 2;
            canvas2.style.width = w + "px";
            canvas2.style.height = h + "px";

             var context = canvas2.getContext("2d");
                context.scale(2, 2);
                html2canvas(document.querySelector('.group-tag-page-center-shera'), { canvas: canvas2 }).then(function(canvas) {

                     var imgUri = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"); // 获取生成的图片的url
              
                    // console.log(imgUri);
                        let link = document.createElement('a');
                        link.setAttribute("href", imgUri);

                        var timestamp = (new Date()).valueOf();
                        // if(this.shareList != undefined && this.shareList != null ){
                            downloadName = downloadName+'.png';
                        // }else{
                        //      downloadName = timestamp+'.png';
                        // }

                        
                        link.setAttribute("download", downloadName);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);

                });
                
                // this.cklickModal(false);


        },
    },
    watch: {
        show: {
            handler(newval, olval) {
                this.tagModalShow = newval.showShare;
                if(newval.sheraList != null){

                  this.shareList = newval.sheraList;
                  this.imgBase();
                     
                
                }
            },
            deep: true,
        }
    }
}
</script>

<style lang="less">
    .group-tag-page-shera {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 10;
        background-color: rgba(0,0,0,.3);
        .group-tag-shera-box{
            position: absolute;
            z-index: 12;
            width: 626px;
            min-height: 595px;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
             .group-tag-shera-close{
                position: absolute;
                font-size: 16px;
                color: #ffffff;
                width: 16px;
                height: 16px;
                cursor: pointer;
                z-index: 13;
                margin-left: 587px;
                margin-top: 27px;
            }
            .group-tap-footer-shera-sava {
              
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                width: 104px;
                border-radius: 19px;
                height: 37px;
                z-index: 13;
                margin-left: 464px;
                margin-top: 22px;
                background-color:#F03500;
                cursor: pointer;
                .group-tap-footer-shera-sava-img{
                    width: 12px;
                    height: 13px;
                    margin-left: 15px;
                }
                .group-tap-footer-shera-sava-span{
                    font-size: 14px;
                    width: 45px;
                    // height: 24px;
                    color: rgb(255, 255, 255);
                    margin-left: 7px;
                    line-height: 24px;
                    margin-top: -3px
                }
                .group-tap-footer-shera-sava-span:hover {
                    // background-color: #FD492B;
                    color: #ada9a9;
                }
            }
        .group-tag-page-center-shera {
            position: absolute;
            // overflow: hidden;
            z-index: 12;
            width: 626px;
            min-height: 595px;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background: url(../../../assets/img/groupImg/shareBackgroundImg.png);
            border-radius: 4px;
            .group-tag-shera-list {
              
                position: absolute;
                width: 400px;
                height: 43px;
                line-height: 43px;
                margin-top: 14px;
                .group-tag-shera-name {
                  position: absolute;
                    width: 400px;
                    height: 25px;
                    font-size: 16px;
                    color: #ffffff;
                    margin-left: 33px;
                    overflow: hidden;
                    display: inline-table;
                }
                .group-tag-shera-desc {
                  position: absolute;
                    width: 400px;
                    height: 14px;
                    font-size: 16px;
                    color: #ffffff;
                    margin-top: 25px;
                    margin-left: 33px;
                    overflow: hidden;
                    display: inline-table;
                }
            }
            .group-tag-shera-head-portrait{
                  width: 88px;
                  height: 88px;
                  position: absolute;
                  margin-top: 106px;
                  margin-left: 269px;
            }
            .group-tag-shera-group-Id{
                width: 626px;
                height: 18px;
                margin-top: 209px;
                text-align: center;
                line-height: 18px;
                position: absolute;
                color: #333333;
                font-size: 16px;
                display: inline-table;
            }
            .group-tag-shera-label{
                width: 626px;
                height: 28px;
                overflow: hidden;
                position: absolute;
                margin-top: 247px;
                line-height: 28px;
                text-align: center;
                .group-tag-shera-label-list{
                    position: relative;
                    width: 88px;
                    height: 28px;
                    background: #F05E35;
                    border-radius: 14px;
                    margin-left: 24px;
                    display: inline-table;
                    color: #FFFFFF;
                }
            }
            .group-tag-shera-rectangular-box{
                background: url(../../../assets/img/groupImg/shareRectangularBox.png);
                position: absolute;
                width: 169px;
                height: 168px;
                margin-top: 295px;
                margin-left: 227px;
                text-align: center;
                .group-tag-shera-rectangular-box-qr-code{
                    border: 2px solid #F9A99A;
                    width: 135px;
                    height: 139px;
                    position: relative;
                    display: inline-table;
                    margin-top: 13px;
                }
            }
            .group-tag-shera-mind{
                width: 626px;
                height: 16px;
                position: absolute;
                margin-top: 481px;
                text-align: center;
                line-height: 16px;
                color: #F5845C;
                font-size: 14px;
                font-family: Noto Sans SC;
            }
            .group-tag-shera-about-app-name{
                width: 626px;
                height: 50px;
                position: absolute;
                margin-top: 518px;
                line-height: 50px;
                text-align: center;
                .group-tag-shera-about-app-name-img{
                    width: 48px;
                    height: 48px;
                }
                .group-tag-shera-about-app-name-span{
                    font-size: 18px;
                    font-family: Noto Sans SC;
                    font-weight: 400;
                    color: #333333;
                }
            }
        
        }
        }
           
    }
</style>