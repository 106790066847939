<template>
    <div class="mind-file-path">

      <div @click="backToThePreviousLevel" class="mind-file-left-img">
          <img class="mind-file-left-img-icon" src="../../../../../assets/img/groupImg/back-father-file.png" alt="">
          <div class="mind-file-left-img-text">
          返回上一级
          </div>
      </div>
      <div v-if="pathShow" class="mind-file-right-box">
          <span class="mind-file-crumbs">
            <span class="mind-file-crumbs-text">
              {{ fileText }}
            </span>
          </span>
      </div>
    </div>
  
</template>

<script>
export default {
  props: {
    mindFileObj: {
      type: Object,
      defualt() {
          return {};
      },
    },
    showPath:{
      type: Boolean,
      defualt() {
          return true;
      },
    }

  },
  data() {
    return {
      text:"",
      fileText: "",
      pathShow:true,
    }
  },
  created(){
    if(this.showPath == false){
      this.pathShow = false;
    }
  },
  methods: {
    backToThePreviousLevel() {
      this.$emit('backToThePreviousLevel',{back: true})
    }
  },
  watch: {
    mindFileObj: {
      handler(newVal) {
        if (this.mindFileObj != null) {
          this.fileText = newVal.path;
          if (this.fileText.length == 0) {
            this.fileText = this.text;
          } else {
            this.fileText = this.text +  this.fileText;
          }
          
        }
      },
      deep: true,
      immediate: true
    },
    showPath: {
      handler(newVal) {
          this.pathShow = newVal;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="less">
    .mind-file-path {
      display: flex;
      height: 32px;
      padding: 0 20px;
      .mind-file-left-img {
        display: flex;
        align-items: center;
        margin-right: 16px;
        cursor: pointer;
        .mind-file-left-img-icon {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
        .mind-file-left-img-text {
          color: #333;
          font-size: 14px;
        }
        
      }
      .mind-file-right-box {
        border: 1px solid #F1F1F1;
        border-radius: 4px;
        line-height: 32px;
        width: calc(100% - 138px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
        // direction: rtl;
        // cursor: pointer;
      }
      
    }

</style>