<template>
    <div @click.stop="hidden(false)" v-show="showModal" class="group-add-new-group">
        <div @click.stop="hidden(true)" class="center-writer">
            <div class="group-setting-administrator-header">
                <div class="administrator-header-left">
                </div>
                <div class="administrator-header-main">
                    {{ $getStringObj.getString($Strings.Mind_Group_Shared_Group_Membership) }}
                </div>
                <div  class="administrator-header-right">
                   <img @click.stop="hidden(false)" src="../../../../assets/img/groupImg/close-modal.png" alt="">
                </div>
            </div>
            <div class="search-header-input">
                <div class="search-left-search">
                    <a-button
                    class="search-search-button"
                    shape="circle"
                    icon="search"
                    size="small"
                    @click="searchButton()"
                    />
                    <input
                    class="search-search-box"
                    type="text"
                    v-model="searchText"
                    v-on:keyup.enter="searchButton()"
                    :placeholder="$getStringObj.getString($Strings.Mind_Group_Members_Of_The_Search)"
                    />
                    <div v-if="searchText != ''" class="cleariconfont search-right-icon" @click="clearSearchText()">&#xe605;</div>
                </div>
            </div>
            <template v-if="isAdminType == 'rember'">
                <div class="group-members-big-box" id="group-members-big-box-children-member" >
                    <div v-if=" gropuMembers != null && gropuMembers.members != null" id="group-members-big-box-children-member-son"  class="group-members-big-box-children">
                        <div v-for="(item,index) in gropuMembers.members " :key="index"  class="members-each">
                            <div class="members-each-left">
                                <!-- <div
                                    class="members-cover"
                                    :style="{
                                            width: 24 + 'px',
                                            height: 24 + 'px',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center center',
                                            overflow: 'hidden',
                                            backgroundImage: 'url('+ httpImageToPrefix(item.avatar) +')',
                                        }"
                                ></div> -->

                                <div :style="{position: 'relative', height: 40 + 'px', width: 40 + 'px', margin: '0 8px 0 0', }" >
                                    <img 
                                        v-if="item.isMember"
                                        :style="{
                                            position: 'absolute',
                                            zIndex: 1,
                                            left: 50 + '%',
                                            transform: 'translate(-50%,0)',
                                            top: -13 + 'px',
                                            width: 24 + 'px',
                                            height: 16 + 'px',
                                        }"
                                        src="../../../../assets/img/groupImg/crown.svg"
                                        alt
                                        />
                                    <div 
                                        :style="{
                                                position: 'absolute',
                                                left: '50%',
                                                bottom: '1px',
                                                transform: 'translate(-50%,0)',
                                                width: 40 + 'px',
                                                height: 40 + 'px',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center center',
                                                overflow: 'hidden',
                                                borderRadius: '50%',
                                                backgroundImage: 'url('+ httpImageToPrefix(item.avatar) +')',
                                            }"
                                    ></div>
                                </div>
                                <div>
                                    {{ item.username }}
                                </div>
                            </div>
                            <div v-if="item.type == 0 " @click="checkAddAdmin(item,index)"  class="members-each-right">
                                {{ $getStringObj.getString($Strings.Mind_Group_Add_As_Administrator) }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else-if="isAdminType == 'searchRember'">
                <div class="group-members-big-box">
                    <div v-if="searchRembersListData != null" class="group-members-big-box-children">
                        <div v-for="(item,index) in searchRembersListData " :key="index" class="members-each">
                            <div class="members-each-left">
                                <div :style="{position: 'relative', height: 40 + 'px', width: 40 + 'px', margin: '0 8px 0 0', }" >
                                    <img 
                                        v-if="item.isMember"
                                        :style="{
                                            position: 'absolute',
                                            zIndex: 1,
                                            left: 50 + '%',
                                            transform: 'translate(-50%,0)',
                                            top: -13 + 'px',
                                            width: 24 + 'px',
                                            height: 16 + 'px',
                                        }"
                                        src="../../../../assets/img/groupImg/crown.svg"
                                        alt
                                        />
                                    <div 
                                        :style="{
                                                position: 'absolute',
                                                left: '50%',
                                                bottom: '1px',
                                                transform: 'translate(-50%,0)',
                                                width: 40 + 'px',
                                                height: 40 + 'px',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center center',
                                                overflow: 'hidden',
                                                borderRadius: '50%',
                                                backgroundImage: 'url('+ httpImageToPrefix(item.avatar) +')',
                                            }"
                                    ></div>
                                </div>
                                <div>
                                    {{ item.username }}
                                </div>
                            </div>
                            <div v-if="item.type == 0 " @click="checkAddAdmin(item,index)"  class="members-each-right">
                                {{ $getStringObj.getString($Strings.Mind_Group_Add_As_Administrator) }}
                            </div>
      
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { postGroupMembers, postGroupSearchGmembers, postGroupAddGadmin} from "../../../../common/netWork/group_api";
import { mapMutations } from "vuex";

import httpImageToPrefix from '../../../../utils/httpImageToPrefix';
export default {
    components: {

    },
    props: [
        "show",
        "groupDataDetail",
    ],
    data() {
        return {
            showModal: false,
            searchText: '', //搜索的内容
            gropuMembers: null, //所有的组成员
            isAdminType: 'rember', //admin 、 searchAdmin、 rember、 searchRember ,判断是管理员页面，还是管理员搜索，还是成员页面，还是成员搜索
            searchRembersListData: null, //搜索的除管理员以外所有人信息
            isScroll:true,
        }
    },
    created() {

    },
    beforeUpdate() {    //每次打开弹窗执行

    },
    methods: {
        ...mapMutations([
            "setgroupAdminMenu",
        ]),

        scrollMoreData() {
            //滚动事件
            let scrollDom = document.getElementById("group-members-big-box-children-member");
            let child = document.getElementById("group-members-big-box-children-member-son");
            if( scrollDom == null || child == null) {
                return;
            }
            const scrollTopHeight = scrollDom.scrollTop ; //滚动高度
            const scrollHeight = scrollDom.clientHeight; //父盒子高度
            const childHeight = child.offsetHeight ; //网页可见区域高(包括边线的宽)
            if (
                scrollTopHeight + scrollHeight >= childHeight &&
                this.isScroll
            ) {
                this.isScroll = false;
                this.scrollPullList()
            }
        },

        scrollPullList() {
            this.pullGroupMembers({ groupId: this.groupDataDetail.id, lastId: this.gropuMembers.admins[this.gropuMembers.admins.length - 1].id }).then(res => {
                if (res != null && res.members != null) {
                    this.gropuMembers.members = this.gropuMembers.members.concat(res.members);
                }
            }).finally(res => {
                this.isScroll = true;
            })
        },

        checkAddAdmin(item, index) {
            this.pushGroupAddGadmin({
                groupId: this.groupDataDetail.id,
                userId: item.id
            }).then(res => {
                this.searchButton();
            })
        },

        //隐藏模态框
        hidden(show) {
            this.showModal = show
                if (show == false) {
                let item = {
                    show: false
                }
                this.$emit("hidden", item);
            }
        },

        searchButton() {
            if ( this.isAdminType == 'rember' || this.isAdminType ==  'searchRember') {
                if (this.searchText == '') {
                    this.refreshGroupMembers()
                    this.isAdminType = 'rember'
                } else {
                    let searchRequestData = {
                        groupId: this.groupDataDetail.id,
                        query: this.searchText,
                        nonAdmin: true 
                    }
                    this.pullGroupSearchGmembers(searchRequestData).then(res => {
                        if(res != null) {
                            this.searchRembersListData = res.members ;
                            if (this.searchRembersListData != null) {
                                for (let index = 0; index < this.searchRembersListData.length; index++) {
                                    this.searchRembersListData[index].isNeedDelet = false;
                                }
                            }
                        }
                    });
                    this.isAdminType =  'searchRember'
                }
            }
        },

        clearSearchText() {
            this.searchText = "";
            this.searchButton()
        },

        httpImageToPrefix(src) {    //拼接图片地址
           return httpImageToPrefix(src)
        },

        timestampToTimeDHM(timestamp) {
            return timestampToTime.formatTime(timestamp);
        },

        //获取所有组员信息
        refreshGroupMembers() {     
            this.pullGroupMembers({ groupId: this.groupDataDetail.id, lastId: '' }).then(res => {
                this.gropuMembers = res;
                if (this.gropuMembers != null && this.gropuMembers.members != null) {
                    for (let index = 0; index < this.gropuMembers.members.length; index++) {
                        this.gropuMembers.members[index].isNeedDelet = false;
                    }
                }

                this.$nextTick(() => {
                    let scrollDom = document.getElementById("group-members-big-box-children-member");
                    if (scrollDom != undefined) {
                        scrollDom.removeEventListener("scroll", this.scrollMoreData); //解除绑定
                        scrollDom.addEventListener("scroll", this.scrollMoreData, false); //添加绑定
                    }
                });
            })
        },
        pushGroupAddGadmin(obj) {
            return new Promise((resolve, reject) => {
                postGroupAddGadmin(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },
        
        pullGroupSearchGmembers(obj) {
            return new Promise((resolve, reject) => {
                postGroupSearchGmembers(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

        pullGroupMembers(obj) {
            return new Promise((resolve, reject) => {
                postGroupMembers(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

    },
    watch: {
        show(newVal) {
            this.showModal = newVal
            if (newVal == true) { //显示模态框请求一次数据
                this.refreshGroupMembers();
            }
        },
        groupDataDetail: {
            handler(newVal,oldVal) {
                if (newVal != null) {
                }
            },
            immediate: true,
            deep: true
        }
    }
}
</script>

<style lang="less" scoped>
    .group-add-new-group {
        position: fixed;
        left: 0; 
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.1);
        z-index: 10;
        .center-writer {
            width: 614px;
            min-height: 400px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background-color: #fff;
            border-radius: 10px;
            .group-setting-administrator-header{
                display: flex;
                padding-top: 15px;
                .administrator-header-left{
                    flex: 0 0 100px;
                    text-align: center;
                    font-size: 12px;
                }

                .administrator-header-main{
                    flex: 1;
                    width: 100%;
                    text-align: center;
                    color: #333;
                    font-size: 16px;
                }

                .administrator-header-right{
                    cursor: pointer;
                    flex: 0 0 100px;
                    text-align: right;
                    font-size: 12px;
                    img {
                        margin-right: 10px;
                    }
                }

            }
            .close-modal {
                position: absolute;
                right: 0;
                top: 0;
                width: 40px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                cursor: pointer;
                font-size: 16px;
                color: #333;
                border-radius: 0 10px 0 0;
            }
            .search-header-input {
                width: 574px;
                height: 32px;
                margin: 0 auto;
                margin-top: 20px;
                margin-bottom: 0px;
                .search-left-search {
                    width: 100%;
                    height: 32px;
                    margin-left: 0px;
                    position: relative;

                    .search-right-icon {
                        width: 30px;
                        height: 30px;
                        line-height: 32px;
                        color: #bbb;
                        font-size: 18px;
                        // background-color: red;
                        position: absolute;
                        right: 0;
                        top: 0;
                        cursor: pointer;
                        &:hover {
                            color: #ff7354;
                        }
                        &:active {
                            color: #d6301a;
                        }
                    }

                    .search-search-button {
                        position: absolute;
                        margin-left: 10px;
                        margin-top: 3px;
                        border: 0px;
                        background: none;
                    }

                    .ant-btn:hover,
                    .ant-btn:focus {
                        background: #fd492b;
                        color: #fff;
                        opacity: 1;
                        border: none;
                    }

                    .search-search-box {
                        width: 100%;
                        border-radius: 15px;
                        border: transparent;
                        background: #F0F2F8;
                        height: 30px;
                        padding-left: 35px;
                    }

                    .search-search-box:focus {
                        outline: none;
                    }
                }
            }

        }

        .group-members-big-box {
            height: 256px;
            margin-top: 20px;
            padding-bottom: 0px;
            overflow-y: auto;
            .group-members-big-box-children {

                .members-each {
                    display: flex;
                    padding: 9px 20px;
                    justify-content: space-between;

                    .members-each-left {
                        display: flex;
                        align-items: center;
                        .members-cover {
                            width: 20px;
                            height: 20px;
                            margin-right: 8px;
                        }
                        .members-name {
                            color: #333;
                            margin-left: 8px;
                        }
                    }
                    .members-each-right {
                        color: #999;
                        cursor: pointer;
                    }
                }

            }
        }

        .group-members-footer-menu {
            position: absolute;
            bottom: 0;
            height: 32px;
            line-height: 32px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding-left: 20px;
            padding-right: 20px;
            .check-rembers-box {

            }
            .all-click-button-box {
                // display: flex;
                text-align: right;
                span {
                    margin: 0 15px;
                    cursor: pointer;
                    &:hover {
                        color: #fd492b;
                    }
                }

            }
        }

    }


</style>