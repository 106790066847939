<template>

    <div class="group-check-tag-page" @click.stop="cklickModal(false)" v-show="tagModalShow">
        <div @click.stop="cklickModal(true)" class="group-check-tag-page-center">
            <div class="each-list" v-for="(item,index) in menuList" :key="index" @click.stop="checkItem(item)" >
                {{ item }}
            </div>
        </div>
    </div>
</template>

<script>

import { postGroupGpermissionSettings, postGroupModifyGpermission } from "../../../../common/netWork/group_api";

export default {
     props: [
        "show",
    ],
    data() {
        return {
            tagModalShow: false,
            groupTagName:'',
            confrim: false,
            warning: '',
            checkVal: '',
            menuList: [],
            gId1:null,
            settingFromePermissions:null,
            typeName:''
        }
    },
    //获取用户组权限
    // created() {
    //     console.log(this.gId1);
            
      
    // },
    methods: {
        cklickModal(val) {
            this.tagModalShow = val;
            if (val == false) { //当隐藏模态框时
                this.hiddenTap({show:false, checkVal:this.checkVal,type: this.show.type})
            }
        },
        hiddenTap(obj) {
            this.$emit("hiddenTap", obj);
        },
        checkItem(item) {
            this.checkVal = item;
            this.cklickModal(false);
            this.settingFromePermissions.groupId = this.gId1;
            if(this.typeName!=""){
                if(item=="所有人"){
                this.settingFromePermissions[this.typeName] = 0;
                }else if(item=="仅管理员"){
                this.settingFromePermissions[this.typeName] = 1;
                }else if(item=="不允许"){
                this.settingFromePermissions[this.typeName] = 2;    
                }
                this.changeGroupSettingsPermissions(this.settingFromePermissions).then((res) => {
                });
            }
        },
        //修改共享组权限管理信息
        changeGroupSettingsPermissions(obj) {
            return new Promise((resolve, reject) => {
                postGroupModifyGpermission(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                });
            });
        },
        //获取共享组权限管理信息
        pullGroupSettingsPermissions(obj){
            return new Promise((resolve, reject) => {
                postGroupGpermissionSettings(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                });
            });
        }
    },
    watch: {
        show: {
            handler(newval, olval) {
                if (newval != null) {
                    this.tagModalShow = newval.show;
                    this.menuList = newval.menuList;
                    this.gId1 = newval.gId;
                    this.typeName = newval.type
                    this.pullGroupSettingsPermissions({groupId: this.gId1}).then((res) => {
                    this.settingFromePermissions = res;
                    })
                }
            },
            deep: true,
        },
    }
}
</script>

<style lang="less">
    .group-check-tag-page {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,.3);
        .group-check-tag-page-center {
            position: absolute;
            width: 300px;
            min-height: 100px;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background-color: #fff;
            border-radius: 4px;
            .each-list {
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 30px;
                padding-right: 30px;
                color: #333;
                cursor:pointer;
            }
        }
    }
</style>