<template>
  <div class="menu-box">
      <div id="leftGroupList" v-if="showDataType == 'myGroup'" class="menu-item">
        <transition name="slide-fade">
        <div v-if="showMyAddGroupList" class="menu-item-child-box" > 
            <div v-for="(item,index) in myGroupData" @click.stop="checkGroupFile(item)" :key="index"
             class="group-message-box clearfix"
             :class="checkGroupData!=null && checkGroupData.id == item.id ? 'group-message-box-check' : ''">
                <div
                    class="group-new-img"
                    :style="{
                            width: 45 + 'px',
                            height: 45 + 'px',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                            overflow: 'hidden',
                            backgroundImage: 'url('+ httpImageToPrefix(item.cover) +')',
                        }"
                    @click.stop="showGroupSetting(item)"
                    
                ></div>
                <div class="group-new-message">
                    <div class="group-name"> 
                        <div class="group-name-text">
                        {{ item.name }}
                        </div>
                        <!-- <div v-if="item.isTop == true " class="group-top">
                            {{ $getStringObj.getString($Strings.Mind_Group_Stick) }}
                        </div> -->
                     </div>
                     <div class="group-new-time group-right-new-time">
                         {{ timestampToTimeDHM(item.updateTime ) }}
                         <img v-if="item.isTop" style="margin-top: -4px; width: 12px" src="../../assets/img/groupImg/setTop.svg" alt="">
                     </div>
                     <div class="group-new-time" style="width:251px;">
                         {{ item.subtitle }}
                     </div>
                </div>
                <div class="more-menu" @mouseover="moveMenu($event,true,item)" @mouseout="moveMenu($event,false,item)">
                    <img class="more-menu-icon" src="../../assets/img/groupImg/more.svg"  alt="">
                </div>
                
                <div v-if="item.unread  == true " class="message-hint">
                    <img src="../../assets/img/groupImg/redFlag.png" alt="">
                    {{ $getStringObj.getString($Strings.Mind_Group_There_Is_A_New_Content) }}
                </div> 

                
                <div v-if="index != myGroupData.length " class="group-bottom-line">
                </div>
            </div>

        </div>
        </transition>
      </div>
      <div v-else-if="showDataType == 'searchMyGroup'" class="menu-item">
        <div class="menu-item-child-box" > 
            <div v-for="(item,index) in searchGroupData" :key="index"  @click.stop="checkGroupFile(item)" 
            class="group-message-box clearfix"
            :class="checkGroupData!=null && checkGroupData.id == item.id ? 'group-message-box-check' : ''">
                <div
                    class="group-new-img"
                    :style="{
                            width: 45 + 'px',
                            height: 45 + 'px',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                            overflow: 'hidden',
                            backgroundImage: 'url('+ httpImageToPrefix(item.cover) +')',
                        }"
                       @click.stop="showGroupSetting(item)" 
                ></div>
                <div class="group-new-message">
                    <div class="group-name"> 
                        <div class="group-name-text">
                        {{ item.name }}
                        </div>
                        <div v-if="item.isTop == true " class="group-top">
                            {{ $getStringObj.getString($Strings.Mind_Group_Stick) }}
                        </div>
                     </div>
                     <div class="group-new-time" >
                         {{ timestampToTimeDHM(item.updateTime ) }}
                     </div>
                     <div class="group-new-time" style="width:251px;">
                         {{ item.subtitle }}
                     </div>
                </div>
                <div class="more-menu" @mouseover="moveMenu($event,true,item)" @mouseout="moveMenu($event,false,item)">
                    <img class="more-menu-icon" src="../../assets/img/groupImg/more.svg"  alt="">
                </div>
                
                <div v-if="item.unread  == true " class="message-hint">
                    <img src="../../assets/img/groupImg/redFlag.png" alt="">
                    {{ $getStringObj.getString($Strings.Mind_Group_There_Is_A_New_Content) }}
                </div> 

                <div v-if="index != searchGroupData.length " class="group-bottom-line">
                </div>
            </div>
        </div>
    </div>
    <GroupMoreMenu :showData="menuList" :fullHeight="fullHeight" :pointObj="pointObj" @checkMenuItem="checkMenuItem" />

    <GroupInviteRembers :groupDataDetail="checkGroupDataDetail" :show="showInput" @hiddenInput="hiddenInput"  />

    
  </div>
</template>

<script>
import httpImageToPrefix from '../../utils/httpImageToPrefix';
import timestampToTime from "../../utils/timestampToTime"
import { mapMutations } from "vuex";
import GroupMoreMenu from "./GroupMoreMenu" //设置组
import { postGroupSetTop, postGroupUnsetTop  } from "../../common/netWork/group_api";
import GroupInviteRembers from "./groupMembersModal/GroupInviteRembers";//邀请新成员输入框
// D:\web\src\components\groupManagementChildren\groupMembersModal\GroupInviteRembers.vue
// D:\web\src\components\groupManagementChildren\GroupMenuBox.vue

export default {
    props: [
        "myGroupData",
        "showDataType",
        "searchGroupData",
        "checkGroupData",
        "checkGroupDataDetail"
    ],
    components: {
        GroupMoreMenu,
        // GroupFile,
        GroupInviteRembers
    },
    data() {
        return {
            showMyAddGroupList: true,
            showFile:false,
            showInput:false,//是否展示邀请输入框
            menuList: [
                this.$getStringObj.getString(this.$Strings.Mind_Group_Stick),
                this.$getStringObj.getString(this.$Strings.Mind_Group_Cancel_The_Top),
                this.$getStringObj.getString(this.$Strings.Mind_Group_Setting_Up),
                this.$getStringObj.getString(this.$Strings.Mind_Group_Invite_New_Members),
                // '置顶',
                // '取消置顶',
                // '设置',
                //'邀请新成员'
            ],
            pointObj: {

            },
            moveItem: null,
            fullHeight: document.documentElement.clientHeight,
        }
    },
    methods: {
        ...mapMutations([
            "setgroupMenu",
            "setRefreshMyGroupList",
            "setGroupDataDetail",
        ]),
        showGroupSetting(item) {
            // this.clickSetting(this.moveItem);
            this.$emit("checkGroup", item);
        },
        moveMenu (e,show,item) {  //节点的e对象。是否展示菜单。展示哪种类型的菜单。
            if (item.isTop) {
                this.menuList = [
                    this.$getStringObj.getString(this.$Strings.Mind_Group_Cancel_The_Top),
                    this.$getStringObj.getString(this.$Strings.Mind_Group_Setting_Up),
                    this.$getStringObj.getString(this.$Strings.Mind_Group_Invite_New_Members),
                    // '取消置顶',
                    // '设置',
                    //'邀请新成员'
                    // {
                    //     name: this.$getStringObj.getString(this.$Strings.Mind_Group_Cancel_The_Top),
                    //     image: require('../../assets/img/groupImg/helpImage.svg')
                    // },
                    // {
                    //     name: this.$getStringObj.getString(this.$Strings.Mind_Group_Setting_Up),
                    //     image: require('../../assets/img/groupImg/helpImage.svg')
                    // },
                    // {
                    //     name: this.$getStringObj.getString(this.$Strings.Mind_Group_Invite_New_Members),
                    //     image: require('../../assets/img/groupImg/helpImage.svg')
                    // }
                ]
            } else {
                this.menuList = [
                    this.$getStringObj.getString(this.$Strings.Mind_Group_Stick),
                    this.$getStringObj.getString(this.$Strings.Mind_Group_Setting_Up),
                    this.$getStringObj.getString(this.$Strings.Mind_Group_Invite_New_Members),
                    // '置顶',
                    // '设置',
                    //'邀请新成员'
                ]
            }
            this.moveItem = item;
            this.pointObj = e.target.getBoundingClientRect();
            if (show) {
                this.setgroupMenu(true);
            } else {
                this.setgroupMenu(false);
            }

        },
        //隐藏输入框
        hiddenInput(obj) {
            if (obj.show == false) {
                if (obj.confrim == true) {
                }
                this.showInput = false;
            }
        },
        checkGroupFile(item) {
            this.$emit("checkGroupFile", item);
        
        },
        clickSetting(item) {
             this.$emit("clickSetting",{item:item, click: true});
        },
        checkMenuItem (val) {
           if (val.val == this.$getStringObj.getString(this.$Strings.Mind_Group_Stick)){
               this.setGroupTop({groupId : this.moveItem.id}).then((res) => {
                    this.setRefreshMyGroupList()
               })
           } else if (val.val == this.$getStringObj.getString(this.$Strings.Mind_Group_Cancel_The_Top)) {
                this.setGroupUnsetTop(({groupId : this.moveItem.id})).then((res) => {
                     this.setRefreshMyGroupList()
                })
           } else if (val.val == this.$getStringObj.getString(this.$Strings.Mind_Group_Setting_Up)) {
                this.clickSetting(this.moveItem);
           }else if (val.val == this.$getStringObj.getString(this.$Strings.Mind_Group_Invite_New_Members)) {
                // this.clickSetting(this.moveItem);
                this.showInput = true;
           }
           //让菜单隐藏。如果不隐藏，会出现鼠标放上去不显示菜单的问题。
           this.setgroupMenu(false);
        },
        httpImageToPrefix(src) {
            return httpImageToPrefix(src)
        },
        timestampToTimeDHM(timestamp) {
            return timestampToTime.formatTime(timestamp);
        },
        setGroupUnsetTop (obj) {
            return new Promise((resolve, reject) => {
                postGroupUnsetTop(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

        setGroupTop (obj) {
            return new Promise((resolve, reject) => {
                postGroupSetTop(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        }
    },
    watch: {
        showDataType: {
            handler(newVal) {
                if (newVal == 'myGroup') {
                   
                }
            },
            immediate: true
        }
    }
}
</script>

<style lang="less">
    .slide-fade-enter-active {
        transition: all 0.3s ease;
    }
    .slide-fade-leave-active {
        transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
    }
    .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active for below version 2.1.8 */ {
        opacity: 0;
    }
    .menu-box {
        // width: 443px;
        .menu-item {
            .menu-item-title {
                color: #FD492B;
                font-size: 14px;
                padding-left: 20px;
                // padding-top: 20px;
                padding-bottom: 10px;
                .show-child-button {
                    margin-left: 16px;
                    color: #333;
                }
            }
            .menu-item-child-box{
                .group-message-box {
                    padding-top: 11px;
                    padding-bottom: 11px;
                    padding-left: 20px;
                    padding-right: 11px;
                    display: flex;
                    position: relative;
                    align-items: center;
                    cursor: pointer;
                    .group-new-img{
                        display: block;
                        width: 53px;
                        height: 53px;
                        border-radius: 4px;
                    }
                    .group-new-message{
                        margin-left: 10px;
                        .group-name {
                            display: flex;
                            align-items: center;
                            .group-name-text {
                                max-width: 251px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                font-size: 14px;
                                color: #333333;
                            }
                            .group-top{
                                font-size: 10px;
                                color: #999999;
                                background-color: #EEEEEE;
                                border-radius: 2px;
                                padding-top: 3px;
                                padding-bottom: 3px;
                                padding-left: 6px;
                                padding-right: 6px;
                                margin-left: 16px;
                            }
                        }
                        .group-new-time {
                            max-width: 251px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            font-size: 12px;
                            color:#999999;
                            margin-top: 5px;
                        }
                        .group-right-new-time{
                            position: absolute;
                            right: 40px;
                            top: 4px;
                        }

                    }
                    .more-menu {
                        display: block;
                        position: absolute;
                        right: 11px;
                        top: 11px;

                        .more-menu-icon {
                            position: absolute;
                            display: block;
                            right: 0;
                            top: 0;
                            width: 14px;
                            height: 14px;
                        }
                    }
                    .message-hint{
                        display: block;
                        position: absolute;
                        right: 15px;
                        bottom: 11px;
                        font-size: 10px;
                        color: #FD492B;
                        img {
                            margin-right: 3px;
                        }
                    }

                    .group-bottom-line {
                        width: 443px;
                        height: 1px;
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        background-color: #EEEEEE;
                    }
                    &:hover{
                        background-color: #F5F5F5;
                    }
                }
                .group-message-box-check {
                    background-color: #F1F1F1;
                }
            }

        }
    }
</style>