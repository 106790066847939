<template>
  <div
    class="group-tag-page"
    @click.stop="cklickModal(false)"
    v-show="tagModalShow"
  >
    <div @click.stop="cklickModal(true)" class="group-tag-page-center">
      <div class="group-tag-detail-list">
        <div class="group-tag-detail-name">
          <div class="group-tag-detail-name-title">
            {{ $getStringObj.getString($Strings.User_Folder_Rename) }}
          </div>
          <div class="group-tag-detail-name-close">
            <img
              class="close-button-img"
              src="../../../../../assets/img/popupsImg/colse_new.svg"
              @click.stop="cancel()"
            />
          </div>
        </div>
        <div style="padding: 0 20px">
          <input
            class="group-tag-detail-input"
            v-model="groupTagName"
            @input="inputArea()"
            v-on:keyup.enter="affirm()"
            placeholder="请输入新名称"
          />
          <div class="tag-warning">
            {{ warning }}
          </div>
        </div>
      </div>
      <div class="group-tap-footer">
        <div
          @click.stop="cancel()"
          class="group-tap-footer-button button-class-colse"
        >
          {{ $getStringObj.getString($Strings.Mind_Group_Cancel) }}
        </div>
        <div
          @click.stop="affirm()"
          class="group-tap-footer-button button-class-ok"
        >
          {{ $getStringObj.getString($Strings.Mind_Group_Confirm) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { postGroupRenameGfile } from "../../../../../common/netWork/group_api";
export default {
  props: ["show", "folderId", "groupDataDetail", "tab", "moveItem"],
  data() {
    return {
      tagModalShow: false,
      groupTagName: "",
      confrim: false,
      warning: "",
    };
  },
  created() {},
  methods: {
    cklickModal(val) {
      this.tagModalShow = val;
      if (val == false) {
        //当隐藏模态框时
        this.hiddenTap({
          show: false,
          confrim: this.confrim,
          groupTagName: this.trim(this.groupTagName),
        });
        this.groupTagName = "";
        this.warning = "";
      }
    },
    hiddenTap(obj) {
      this.$emit("hiddenChangName", obj);
    },
    affirm() {
      if (this.trim(this.groupTagName) == "") {
        this.warning = "文件夹名称不能为空"; //this.$getStringObj.getString(this.$Strings.Mind_Group_The_Content_Cannot_Be_Empty)
        return;
      }
      this.confrim = true;
      // let parentId =  nowFileObj
      let obj = {
        gfileId: this.moveItem.id,
        toName: this.trim(this.groupTagName),
      };
      this.setGroupRenameGfile(obj).then((res) => {
        this.cklickModal(false);
        this.confrim = false;
      });
    },
    cancel() {
      this.confrim = false;
      this.cklickModal(false);
    },
    trim(str) {
      //判断有首尾空格去除空格
      return str.replace(/^\s+|\s+$/g, "");
    },
    inputArea() {
      //不允许输入或黏贴超过20个字符
      this.warning = "";
      if (this.groupTagName.length > 20) {
        this.groupTagName = this.groupTagName.slice(0, 20);
      }
    },
    setGroupRenameGfile(obj) {
      return new Promise((resolve, reject) => {
        postGroupRenameGfile(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            this.$message.error(error.desc);
            reject(error);
          }
        );
      });
    },
  },
  watch: {
    show: {
      handler(newval, olval) {
        this.tagModalShow = newval;
      },
      deep: true,
    },
    moveItem: {
      handler(newval, olval) {
        this.groupTagName = newval.name; //重命名默认显示文件名字
      },
      deep: true,
    },
    groupTagName: {
      handler(newval, olval) {},
    },
  },
};
</script>

<style lang="less">
.group-tag-page {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  .group-tag-page-center {
    position: absolute;
    width: 300px;
    min-height: 150px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 4px;
    .group-tag-detail-list {
      width: 230px;
      font-size: 14px;
      line-height: 40px;
      color: #333333;
      margin: 0 auto;
      margin-bottom: 20px;
      .group-tag-detail-name {
        // margin-right: 20px;
        text-align: center;
        font-size: 16px;
        margin-bottom: 10px;
        .group-tag-detail-name-title {
          position: absolute;
        }
        .group-tag-detail-name-close {
          position: relative;
          float: right;
          .close-button-img {
            width: 16px;
            cursor: pointer;
            opacity: 0.5;
          }
          .close-button-img:hover {
            opacity: 0.8;
          }
        }
      }

      .group-tag-detail-input {
        width: 100%;
        // width: 230px;
        height: 38px;
        padding-left: 10px;
        border: 1px solid #e2e2e2;
        background-color: #f0f2f8;
        outline: none;
        border-radius: 2px;
        font-size: 12px;
      }
      .tag-warning {
        // text-align: center;
        color: #fd492b;
      }
    }
    .group-tap-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      .group-tap-footer-button {
        padding: 0 12px;
        margin: 0 20px;
        height: 24px;
        text-align: center;
        line-height: 24px;
        border-radius: 12px;
        font-size: 14px;
      }
      .button-class-ok {
        background: #fd492b;
        color: #fff;
      }
      .button-class-colse {
        border: 1px solid #f0f2f8;
        color: #999999;
      }
      .button-class-colse:hover {
        color: #fd492b;
        border: 1px solid #fd492b;
        background-color: #fff;
      }
      .button-class-ok:hover {
        background: #ff7354;
      }
    }
  }
}
</style>