import { render, staticRenderFns } from "./GroupAddGroupMenu.vue?vue&type=template&id=6e925058&scoped=true"
import script from "./GroupAddGroupMenu.vue?vue&type=script&lang=js"
export * from "./GroupAddGroupMenu.vue?vue&type=script&lang=js"
import style0 from "./GroupAddGroupMenu.vue?vue&type=style&index=0&id=6e925058&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e925058",
  null
  
)

export default component.exports