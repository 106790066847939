<template>
  <div class="group-setting-page" :style="{height: fullHeight - 60 + 'px'}">
      <div class="group-setting-page-center">
        <div :style="{height: fullHeight - 60 + 'px'}" class="group-setting-left-menu">
            <div v-for="(item,index) in menuList" :key="index" @click="checkItem(item)" class="group-setting-menu-list-box">
                <div class="group-setting-menu-list" :class="item.name == checkData.name ? 'group-setting-menu-list-check' : '' ">
                    <img v-if="item.name == checkData.name" :src="item.checkImage" alt="">
                    <img v-else  :src="item.image" alt="">
                    <div class="group-setting-list-name" :class=" item.name == checkData.name ? 'group-setting-list-name-check': '' ">
                        {{ item.name }}
                    </div>
                </div>
            </div>
            <div class="admin-rights-title"></div>
            <div v-for="(item) in menuUpgradeList" :key="item.id" @click="checkItem(item)" class="group-setting-menu-list-box">
                <div class="group-setting-menu-list" :class="item.name == checkData.name ? 'group-setting-menu-list-check' : '' ">
                    <img v-if="item.name == checkData.name" :src="item.checkImage" alt="">
                    <img v-else  :src="item.image" alt="">
                    <div class="group-setting-list-name" :class=" item.name == checkData.name ? 'group-setting-list-name-check': '' ">
                        {{ item.name }}
                    </div>
                </div>
            </div>
            <div v-if="this.settingPermissionsFrome!=null &&this.settingPermissionsFrome.memberType != null&&this.settingPermissionsFrome.memberType != 2" @click="clickDissolve" class="dissolve-button" >
                {{ $getStringObj.getString($Strings.Mind_Group_Quit_Sharing_Of_Group) }}
            </div>
            <div v-else @click="clickDissolve" class="dissolve-button" >
                {{ $getStringObj.getString($Strings.Mind_Group_The_Dissolution_Of_Group) }}
            </div>
        </div>
        <div class="group-setting-right-content">
            <GroupSettingPageData v-if="checkData.name ==  $getStringObj.getString($Strings.Mind_Group_Shared_Group_Data) " />
            <GroupSettingPageAdministrator v-else-if="checkData.name == $getStringObj.getString($Strings.Mind_Group_Administrator)" />
            <GroupSettingRightsOfAdministrators v-else-if="checkData.name == $getStringObj.getString($Strings.Mind_Group_Authority_Management)" />
            <SetMemberLimitUpgrade v-else-if="checkData.name == $getStringObj.getString($Strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members)" />
            <SetStorageSpaceUpgrade v-else-if="checkData.name == $getStringObj.getString($Strings.Used_Space_Upgrade)" />
            <!-- <template v-if="checkData.name == '共享组资料'">

            </template> -->
            <!-- <div class="group-administrator" v-else-if="checkData.name == '管理员'">

            </div>
            <div class="group-rights-of-administrators" v-else-if="checkData.name == '管理员权限'">

            </div> -->
        </div>
      </div>
    <!-- <div class="footer-box">
        <div @click="clickDissolve" class="dissolve-button">
            {{ $getStringObj.getString($Strings.Mind_Group_The_Dissolution_Of_Group) }}
        </div>
    </div> -->
    <AlterModal :title=" $getStringObj.getString($Strings.Mind_Group_The_Dissolution_Of_Group) " 
        :show="showAlter" @hiddenAlter="hiddenAlter" @hiddenAlterShow="hiddenAlterShow" />
  </div>
</template>

<script>
import { postGroupDeleteGroup,postGroupGsettings ,postGroupLeaveGroup} from "../../../common/netWork/group_api";
import httpImageToPrefix from '../../../utils/httpImageToPrefix';
import GroupSettingPageData from './groupSettingPageChildren/GroupSettingPageData';
import GroupSettingPageAdministrator from './groupSettingPageChildren/GroupSettingPageAdministrator';
import GroupSettingRightsOfAdministrators from './groupSettingPageChildren/GroupSettingRightsOfAdministrators';
import SetMemberLimitUpgrade from './groupSettingPageChildren/SetMemberLimitUpgrade';
import SetStorageSpaceUpgrade from './groupSettingPageChildren/SetStorageSpaceUpgrade';
import AlterModal from './groupSettingPageChildren/AlterModal';
import { mapMutations } from "vuex";
export default {
    components: {
        GroupSettingPageData,
        GroupSettingPageAdministrator,
        GroupSettingRightsOfAdministrators,
        AlterModal,
        SetMemberLimitUpgrade,
        SetStorageSpaceUpgrade
    },
    props: [
        'fullHeight'
    ],
    data() {
        return {
            menuList:[
                {
                    name: this.$getStringObj.getString(this.$Strings.Mind_Group_Return_To_Home_Page) ,
                    image: require('../../../assets/img/groupImg/setting-menu-1.png'),
                    checkImage: require('../../../assets/img/groupImg/setting-menu-1.png'),
                },
                {
                    name: this.$getStringObj.getString(this.$Strings.Mind_Group_Shared_Group_Data) ,
                    image: require('../../../assets/img/groupImg/setting-menu-2.png'),
                    checkImage: require('../../../assets/img/groupImg/setting-menu-2-2.png'),
                },
                {
                    name: this.$getStringObj.getString(this.$Strings.Mind_Group_Administrator) ,
                    image: require('../../../assets/img/groupImg/setting-menu-3.png') ,
                    checkImage: require('../../../assets/img/groupImg/setting-menu-3-2.png'),
                },
                {
                    name: this.$getStringObj.getString(this.$Strings.Mind_Group_Authority_Management) ,
                    image: require('../../../assets/img/groupImg/setting-menu-4.png'),
                    checkImage: require('../../../assets/img/groupImg/setting-menu-4-2.png'),
                }
                
            ],
            menuUpgradeList:[
                {
                    name: this.$getStringObj.getString(this.$Strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members) ,
                    image: require('../../../assets/img/groupImg/setting-menu-5.png'),
                    checkImage: require('../../../assets/img/groupImg/setting-menu-5-2.png'),
                    id:4
                },
                {
                    name: this.$getStringObj.getString(this.$Strings.Used_Space_Upgrade) ,
                    image: require('../../../assets/img/groupImg/setting-menu-6.png'),
                    checkImage: require('../../../assets/img/groupImg/setting-menu-6-2.png'),
                    id:5
                }
            ],
            checkData: null,
            showAlter: {showTag:false},
            groupDataDetail: null,
            settingPermissionsFrome:null,
            showList:null,
            showMonitor:null,
            content:this.$getStringObj.getString(this.$Strings.Mind_Group_The_Files_Will_Be_Deleted_After_Disbanding_The_Group_Confirm_Disbanding)
        }
    },
    created() {
        if(this.showMonitor == null || this.showMonitor == ''){
            this.checkItem(this.menuList[1])
        }else if(this.showMonitor == this.$getStringObj.getString(this.$Strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members)){
           this.checkItem(this.menuUpgradeList[0]) ;
           this.showMonitor = null;
        }else if(this.showMonitor == this.$getStringObj.getString(this.$Strings.Used_Space_Upgrade)){
           this.checkItem(this.menuUpgradeList[1]) ;
           this.showMonitor = null;
        }
    },
    mounted() {
        this.pullPermissionsGroupSettings({groupId: this.groupDataDetail.id}).then((res) => {
            this.settingPermissionsFrome = res; 
            if(this.settingPermissionsFrome && this.settingPermissionsFrome.memberType !== 2){//不是创建者不显示 管理员和权限管理  
                this.menuList.splice(2, 2)
            }
        })
    },
    destroyed() {
        this.setGroupDataDetailParameter('noChangeData');
    },
    methods: {
        ...mapMutations([
            "setGroupDataDetail",
            "setGroupDataDetailParameter",
            "setRefreshMyGroupList"
        ]),
        clickDissolve() {
            let obj;
          obj = {
            menuList:this.settingPermissionsFrome,
            showTag: true,
            content:this.$getStringObj.getString(this.$Strings.Group_Mind_Play_The_File_Will_Not_Exist)
          }
          this.showAlter = obj;
           
        },
        beforeunloadFn (e) {
            this.$router.push("/Home/GroupManagement");
        },
        httpImageToPrefix(src) {
            return httpImageToPrefix(src)
        },
        hiddenAlter(obj) {
            if (obj.show == false) {
                if (obj.confrim == true) {
                    if(this.settingPermissionsFrome!=null && this.settingPermissionsFrome.memberType == 2){
                            this.dissolveGroup({ groupId: this.groupDataDetail.id  }).then((res)=> {
                                this.setRefreshMyGroupList();
                                this.setGroupDataDetail('noChangeData');
                            });
                        
                    }else{
                        this.quitSharingGroup({ groupId: this.groupDataDetail.id  }).then((res)=> {
                            this.setRefreshMyGroupList();
                            this.setGroupDataDetail('noChangeData');
                        });
                        
                    }
                    
                }
                
                            this.showAlter.showTag = false;
                
            }
        },
        hiddenAlterShow(obj){
             if (obj.show == false) {
                if (obj.confrim == true) {
                    // console.log(obj);
                    if(this.settingPermissionsFrome!=null && this.settingPermissionsFrome.memberType == 2){                            
                            this.showAlter.showTag = false;
                            
                            let obj;
                            obj = {
                                menuList:this.settingPermissionsFrome,
                                showTag: true,
                                content: this.content
                            }
                            this.showAlter = obj;
                        
                    }else{
                        this.quitSharingGroup({ groupId: this.groupDataDetail.id  }).then((res)=> {
                            this.setRefreshMyGroupList();
                            this.setGroupDataDetail('noChangeData');
                        });
                        this.showAlter.showTag = false;
                        
                    }
                    
                }
                
            }
        },
        checkItem(item) {
            this.checkData = item;
            if (item.name == this.$getStringObj.getString(this.$Strings.Mind_Group_Return_To_Home_Page)) {
                // this.$router.push("/Home/GroupManagement");
                this.setGroupDataDetail('noChangeData');
            }
        },
        //解散共享组
        dissolveGroup(obj) {
            return new Promise((resolve, reject) => {
                postGroupDeleteGroup(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                });
            });
        },
        //获取共享组权限管理信息
        pullPermissionsGroupSettings(obj){
            return new Promise((resolve, reject) => {
                postGroupGsettings(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                });
            });
        },
        //退出共享組
        quitSharingGroup(obj) {
            return new Promise((resolve, reject) => {
                postGroupLeaveGroup(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                });
            });
        }
    },
    watch: {
        '$store.state.showGroupSetting' : {
            handler(newVal,oldVal) {
                this.showSettingPage = newVal;
                // this.refreshList();
            },
            immediate: true,
        },
     
        "$store.state.groupDataDetail" : {
            handler(newVal,oldVal) {
                if (newVal != null) {
                  this.groupDataDetail = newVal;
                  
                }
            },
            immediate: true,
            deep: true,
        },
        "$store.state.rootDirectory" : {
            handler(newVal,oldVal) {
                if (newVal != null) {
                    if(newVal==this.$getStringObj.getString(this.$Strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members)){
                        this.showMonitor = newVal;
                    }else if(newVal==this.$getStringObj.getString(this.$Strings.Used_Space_Upgrade)){
                        this.showMonitor = newVal;
                    }
                }
            },
            immediate: true,
            deep: true,
        },
        
    }
}
</script>

<style lang="less">

.admin-rights-title {
        padding-left: 20px;
        height: 8px;
        line-height: 22px;
        background-color: #ECECEC;
      }

    .group-setting-page {
        // height:calc(100vh - 80px); 
        background-color: #fff;
        overflow-y: auto;
        .group-setting-page-center {
            margin: 0 auto;
            max-width: 1211px;
            // height:calc(100vh - 80px); 
            display: flex;
            .group-setting-left-menu {
                position: fixed;
                width: 290px;
                display: inline-block;
                // min-height: calc(100vh - 80px); 
                background-color: #f6f6f6;
                .group-setting-menu-list-box {
                    background-color: #f6f6f6;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    cursor: pointer;
                    .group-setting-menu-list {
                        height: 46px;
                        border-left: 6px solid rgba(0,0,0,0);
                        padding-left: 85px;
                        display: flex;
                        align-items: center;
                        img {
                            // width: 16px;
                            // height: 16px;
                            margin-right: 12px;
                        }
                        .group-setting-list-name {
                            color: #333;
                            font-size: 14px;
                        }
                        .group-setting-list-name-check {
                            color: #FD492B;
                        }
                        &:hover{
                            background-color: #fff;
                            .group-setting-list-name {
                                color: #FD492B;
                                font-size: 14px;
                            }
                        }
                    }
                    .group-setting-menu-list-check {
                        border-left: 6px solid #FD492B;
                        background-color: #fff;
                    }
                   
                }
                .dissolve-button{
                    // width: 40px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    position: absolute;
                    left: 100px;
                    bottom: 44px;
                    color: #999;
                    font-size: 12px;
                    cursor: pointer;
                    &:hover {
                        color: #FD492B;
                    }
                }
            }
            .group-setting-right-content {
                display: inline-block;
                margin-left: 290px;
                width: 1200px;
                // .group-data {

                // }
            }
        }

    }
</style>