<template>
  <div class="move-father-box" @click="hideMoveFile(false)" v-show="moveFile">
    <div @click.stop="hideMoveFile(true)" class="move-child-box">
      <div class="box-header">
        <!-- 创建导图 -->
        {{getString(Strings.Create_Map)}}
      </div>
      <img
        class="close-icon"
        @click.stop="hideMoveFile(false)"
        src="../../../../../assets/img/groupImg/close-modal.png"
        alt=""
      />
      <div class="created-content-box menu-silder">
            <div v-for="(item, index) in templateData" :key="index" class="modal-class">
                
                <template v-if="index < 6">
                  <div class="modal-class-name">
                    {{ getString(item[0].name )}}
                  </div>
                  <NewModalCreatedFileScroll
                    :data="item"
                    :groupDataDetail="groupDataDetail"
                    :folderId="folderId"
                    @clickCreated="clickCreated"
                    :folder="crumbs.slice(-1)[0]"
                  />
                </template>
                <template v-else>
                  <div class="modal-class-name">
                    {{ getString(item[0].name )}}
                  </div>
                  <GroupCreatedFileScroll
                    :data="item"
                    :groupDataDetail="groupDataDetail"
                    :folderId="folderId"
                    @clickCreated="clickCreated"
                    :folder="crumbs.slice(-1)[0]"
                  />
                </template>
                <!-- <div class="modal-class-name">
                    {{ getString(item[0].name )}}
                </div>
                <GroupCreatedFileScroll
                    :data="item"
                    :groupDataDetail="groupDataDetail"
                    :folderId="folderId"
                    @clickCreated="clickCreated"
                    :folder="crumbs.slice(-1)[0]"
                /> -->
            </div>
      </div>
    </div>
  </div>
</template>

<script>

import HttpServerConfig from "../../../../../common/HttpServerConfig";
import timestampToTime from "../../../../../utils/timestampToTime";

import { postTemplateNewFromTemplate } from "../../../../../common/netWork/template_api";
import CreatMindItmeData from "../../../../../views/uidata/CreatMindItmeData";

import LineLayout from "../../../../../viewmodel/datatype/LineLayout";
import MindDisplayType from "../../../../../viewmodel/datatype/MindDisplayType";
import MindType from "../../../../../viewmodel/datatype/MindType";
import NodeLayoutType from "../../../../../viewmodel/datatype/NodeLayoutType";

import GroupCreatedFileScroll from "../../../../createdFile/createdFileScroll/GroupCreatedFileScroll";
import NewModalCreatedFileScroll from "../../../../createdFile/createdFileScroll/NewModalCreatedFileScroll";
import Strings from "../../../../../common/lang/strings";
import getString from "../../../../../common/lang/language";
import createdMindData from "../../../../../common/createdMindData";
import CreateMapDatas from '../../../../../common/CreateMapDatas';

export default {
  name: "GroupMoveFile",
  props: ["moveFile", "groupDataDetail", "moveItem", "folderId"],
  components: {
    GroupCreatedFileScroll,
    NewModalCreatedFileScroll,
  },
  data() {
    return {
      Strings,
      templateData: [],
      creatMindTypeLeftRight: [],
      creatMindTypeUnilateral: [],
      creatMindTypeBottom: [],
      creatMindTypeBubble: [],
      creatMindTypeTimeVertical: [],
      urlPrefix: "../../../../../../assets/img/mindNewEditMenuIocn/oneKeyDiscoloration/skeleton2/", //地址前缀
      crumbs: [{ id: "", name: getString(Strings.Create_Map) }], //面包屑
    };
  },
    created() {
      this.templateData = createdMindData
    // this.createdType();
    //创建页面时要请求vuex里面的面包屑
    //this.crumbs = this.$store.state.createdMindCrumbsArr;
  },
  methods: {
    getString(i) {
      return getString(i);
    },
    pullModalData(obj) {
      let that = this;
      return new Promise(function (resolve, reject) {
        postTemplateNewFromTemplate(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
            that.$message.error(error.desc);
          }
        );
      });
    },
    clickCreated(item) {
        this.hideMoveFile(false, true);
    //   this.bus.$emit("changeCreated", item);

    },
    createdType() {
      let createMapDatas = new CreateMapDatas()
      this.creatMindTypeLeftRight = createMapDatas.creatMindTypeLeftRight
      this.creatMindTypeUnilateral = createMapDatas.creatMindTypeUnilateral
      this.creatMindTypeBottom = createMapDatas.creatMindTypeBottom
      this.creatMindTypeBubble = createMapDatas.creatMindTypeBubble
      this.creatMindTypeTimeVertical = createMapDatas.creatMindTypeTimeVertical

      this.templateData.push(this.creatMindTypeLeftRight);
      this.templateData.push(this.creatMindTypeUnilateral);
      this.templateData.push(this.creatMindTypeBottom);
      this.templateData.push(this.creatMindTypeBubble);
      this.templateData.push(this.creatMindTypeTimeVertical);

      for (let i = 0; i < this.templateData.length; i++) {
        for (let j = 0; j < this.templateData[i].length; j++) {
          if (this.templateData[i][j] != null) {
            this.templateData[i][
              j
            ].icon = require("../../../../../assets/img/mindNewEditMenuIocn/oneKeyDiscoloration/skeleton2/" +
              this.templateData[i][j].icon);
          }
        }
      }
    },



    httpImageToPrefix(src) {
      return HttpServerConfig.getMediaSrc(src);
    },

    timestampToTimeDHM(timestamp) {
      return timestampToTime.formatTime(timestamp);
    },

    hideMoveFile(show, isCreated) {
      let obj = {};
      obj.show = show;
      obj.isCreated = isCreated;
      this.$emit("hideMoveFile", obj);
    },
  },
  watch: {
    moveFile: {
      handler(val) {
        if (val == true) {
          //   this.mindFileObj = null; //导图列表数据
          //   this.page = 1; //当前所在页面
          //   this.moveFolderId = "", //当前所在文件夹的id
          //   this.gfilesArr = []; //群组文件夹内文件的数组。
          // this.refreshMindList();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="less" scoped>
// @font-face {
//   font-family: "fileiconfont";
//   src: url("../../../../../assets/font-icon/font_tclfjh9pcm/iconfont.eot");
//   src: url("../../../../../assets/font-icon/font_tclfjh9pcm/iconfont.eot?#iefix")
//       format("embedded-opentype"),
//     url("../../../../../assets/font-icon/font_tclfjh9pcm/iconfont.woff2")
//       format("woff2"),
//     url("../../../../../assets/font-icon/font_tclfjh9pcm/iconfont.woff")
//       format("woff"),
//     url("../../../../../assets/font-icon/font_tclfjh9pcm/iconfont.ttf")
//       format("truetype"),
//     url("../../../../../assets/font-icon/font_tclfjh9pcm/iconfont.svg#iconfont")
//       format("svg");
// }
// .fileiconfont {
//   font-family: "fileiconfont" !important;
//   font-size: 16px;
//   font-style: normal;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

.move-father-box {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);

  .move-child-box {
    width: 815px;
    height: 620px;
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .box-header {
      text-align: center;
      padding-top: 15px;
      font-size: 16px;
      color: #333;
      font-weight: bold;
    }
    .close-icon {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 18px;
      top: 17px;
      background-color:#ccc;
      border-radius: 10px;
      padding: 3px;
      cursor: pointer;
    }
    .created-content-box {
        height: 540px;
        overflow-y: auto;
        margin-right: 2px;
        .modal-class-name {
            font-size: 14px;
            color: #333;
            padding: 10px 0 10px 45px;
            font-weight: bold;
        }
    }
    .menu-silder::-webkit-scrollbar {
      // width: 340px;
      width: 8px;
      scrollbar-arrow-color: rgba(0,0,0,0.1);
    }
    .menu-silder::-webkit-scrollbar-thumb {
      border-radius: 16px;
      background: rgba(0,0,0,0.1);
    }
    .menu-silder::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px #ffffff;
      border-radius: 16px;
      background: #ffffff;
    }
  }

  // .move-child-box .move-header{
  //     height: 50px;
  // }
  // .move-child-box .move-header {
  //     padding-top: 10px;
  //     padding-left: 0px;
  // }
  // .move-child-box .move-header .mind-element{
  //   font-size: 16px;
  //   cursor: pointer;
  // }

  // .move-child-box .move-header .mind-element:nth-last-child(1) i{
  //   display: none;
  // }

  // .move-child-box .move-content {
  //   overflow: hidden;
  //     height: 260px;
  //     max-height: 260px;
  //     overflow-y: scroll;
  // }
  // .move-child-box .move-footer {
  //     display:flex;
  //     justify-content: center;

  // }
  // .move-child-box .move-footer .button-class {
  //     margin: 0 20px;
  //     margin-top: 10px;
  //     width: 60px;
  //     height: 30px;
  //     border-radius: 15px;
  //     text-align: center;
  //     line-height: 30px;
  //     font-size: 16px;
  //     background-color: #F0F2F8;
  //     cursor: pointer;
  // }
  // .move-child-box .move-footer .button-class:hover {
  //     background-color: #ff7354;
  //     color: #fff;
  // }

  //     .mind-file-list-box {
  //       //  padding-bottom: 150px;
  //        height: 260px;
  //        overflow-y: auto;
  //       .mind-file-list-box-each {
  //         height: 70px;
  //         padding: 0 20px;
  //         padding-top: 20px;
  //         cursor: pointer;
  //         .mind-each-left {
  //           width: 56px;
  //           height: 56px;
  //           float: left;
  //           margin-left: -100%;
  //         }
  //         .mind-each-center {
  //           width: 100%;
  //           float: left;
  //           .mind-each-center-title {
  //             color: #333;
  //             font-size: 16px;
  //             height: 30px;
  //             .mind-each-center-title-content {
  //               padding-left: 63px;
  //               display: flex;
  //               .title-content-name {
  //                 max-width: calc(100% - 138px);
  //                 overflow: hidden;
  //                 white-space: nowrap;
  //                 text-overflow: ellipsis;
  //               }
  //               .mind-each-center-title-stick {
  //                 background-color:rgba(0, 0, 0, 0.05);
  //                 color: #EC705A;
  //                 font-size: 12px;
  //                 padding: 0 4px;
  //                 height: 19px;
  //               }
  //             }
  //             .mind-each-center-title-sub-content {
  //               font-size: 14px;
  //               margin-top: 4px;
  //               padding-left: 63px;
  //               padding-right: 107px;
  //               color: #666;
  //               overflow: hidden;
  //               white-space: nowrap;
  //               text-overflow: ellipsis;
  //             }
  //           }
  //         }
  //         .mind-each-right {
  //           float: right;
  //           width: 100px;
  //           height: 56px;
  //           margin-left: -100px;
  //           .date-detail {
  //             color: #333;
  //             text-align: right;
  //           }
  //           .mind-file-menu-icon {
  //             float: right;
  //             padding: 2px 10px;
  //           }
  //         }
  //       }

  //       .each-box-bottom-line {
  //         height: 2px;
  //         background-color: #f6f6f6;
  //         width: calc(100% - 102px);
  //         margin-right: 20px;

  //         margin-top: 10px;
  //         float: right;
  //       }
  //     }
}
</style>