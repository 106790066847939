<template>
  <div v-if="checkGroupDataDetail != null" :style="{height: fullHeight - 60 + 'px'}" class="group-detail">
  <!-- <div v-if="checkGroupDataDetail != null" ref="groupDtail" class="group-detail"> -->
    <template v-if="showFile == false">

      <div class="group-detail-header">
        <img class="group-detail-header-img" :src="httpImageToPrefix(checkGroupDataDetail.cover)" alt="" />
        <div class="group-detail-header-right">
          <div class="group-detail-title">{{ checkGroupDataDetail.name }}</div>
          <div class="group-detail-id" @click="copyGroupId()">{{ $getStringObj.getString($Strings.Mind_Group_Group_Indication) }}:{{checkGroupDataDetail.code}}</div>
          <div v-if="checkGroupDataDetail.desc != '' && checkGroupDataDetail.desc != null" class="group-detail-introductory">{{ checkGroupDataDetail.desc }}</div>
          <div class="group-detail-introductory" v-else>
            {{ $getStringObj.getString($Strings.Mind_Group_The_Creator_Is_Lazy_And_Hasnt_Been_Introduced_Yet) }}
          </div>
          <div class="group-detail-button-box">
            <div v-for="(item,index) in checkGroupDataDetail.tags" :key="index" class="group-botton-class">{{ item }}</div>
          </div>
        </div>
        <div class="group-operation">
          <img @click="clickSetting" src="../../assets/img/groupImg/setting.svg" class="img-margin" alt="" />
          <img @click="clickShare" src="../../assets/img/groupImg/share.svg" alt="" />
        </div>
      </div>
      <div class="group-notice background-dom">
        <div class="gruop-secondary-title">{{ $getStringObj.getString($Strings.Mind_Group_Shared_Group_Announcement) }}</div>
        <template v-if="checkGroupDataDetail.notice != '' && checkGroupDataDetail.notice != null">
          <div class="group-notice-content-father">
            <div ref="groupNoticeContent" class="group-notice-content" :class="showNotice ? '': 'beyondHidden'">
              {{ checkGroupDataDetail.notice }}
            </div>
            <div v-if="allowShowButton && showNotice == false" @click="showNoticeChange" class="click-unfold">
                            <div class="left-linear-gradient">

              </div>
              <div class="click-unfold-text">
                {{ $getStringObj.getString($Strings.Mind_Group_Click_On) }}
              </div>
            </div>
            <div v-if="showNotice" @click="showNoticeChange" class="click-unfold">
              <div class="left-linear-gradient">

              </div>
              <div class="click-unfold-text">
                {{ $getStringObj.getString($Strings.Mind_Group_Click_On_The_Hidden) }}
              </div>

            </div>
          </div>
        </template>
        <template v-else>
            <div class="group-notice-content text-center" :style="{'padding': '20px 0'}">
              <div class="no-announcement" style="display: flex;justify-content: center;align-items: center;">
                <img src="../../../static/img/share/no_announcement.svg" alt="" style="margin-bottom: 8px;">
              </div>
              {{ $getStringObj.getString($Strings.Mind_Group_No_Announcement) }}
            </div>
        </template>
      </div>
      <div class="group-member background-dom">
        <div class="gruop-secondary-title">{{ $getStringObj.getString($Strings.Mind_Group_Shared_Group_Membership) }}</div>
        <div class="group-member-content" :class="showAllMembers? 'beyondCoverShow' : '' ">
          <div class="group-member-detail" style="cursor : pointer;" @click.stop="invitationNewMembers">
            <div :style="{ height: 75 + 'px', width: 56 + 'px', margin: '0 auto', position: 'relative' }" >
                <img class="groupInvitation-user" src="../../assets/img/groupImg/groupInvitation.svg"/>
                <!--<div 
                    :style="{
                            position: 'absolute',
                            left: '50%',
                            bottom: '1px',
                            transform: 'translate(-50%,0)',
                            width: 56 + 'px',
                            height: 56 + 'px',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                            zIndex: 1,
                            overflow: 'hidden',
                            borderRadius: '50%',
                            backgroundImage: 'url('+ require('../../assets/img/groupImg/groupInvitation.svg') +')',
                        }"
                ></div>-->
            </div>
            <!-- <img class="group-user-header" :src="httpImageToPrefix(item.avatar)"  alt="" /> -->
            <div class="group-name">{{ $getStringObj.getString($Strings.Mind_Group_Invite_New_Members) }}</div>
          </div>
          <div v-for="(item,index) in checkGroupDataDetail.members" :key="index" class="group-member-detail" @click="getUserProfile(item.id)">
            <div :style="{ height: 75 + 'px', width: 56 + 'px', margin: '0 auto', position: 'relative' }" >
                <img 
                    v-if="item.isMember"
                    :style="{
                        position: 'absolute',
                        width: 56 + 'px',
                        height: 75 + 'px',
                    }"
                    src="../../assets/img/user/crown.png"
                    alt
                    />
                <div 
                  v-if="item.avatar"
                  :style="{
                          position: 'absolute',
                          left: '50%',
                          bottom: '1px',
                          transform: 'translate(-50%,0)',
                          width: 54 + 'px',
                          height: 54 + 'px',
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                          backgroundPosition: 'center center',
                          zIndex: 1,
                          overflow: 'hidden',
                          borderRadius: '50%',
                          backgroundImage: 'url('+ httpImageToPrefix(item.avatar) +')',
                      }"
                ></div>
                <div 
                  v-else
                  :style="{
                          position: 'absolute',
                          left: '50%',
                          bottom: '1px',
                          transform: 'translate(-50%,0)',
                          width: 54 + 'px',
                          height: 54 + 'px',
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                          backgroundPosition: 'center center',
                          zIndex: 1,
                          overflow: 'hidden',
                          borderRadius: '50%',
                          backgroundImage: 'url(https://r.mindyushu.com/r/config/image/default_avatar.png)',
                      }"
                ></div>
            </div>
            <!-- <img class="group-user-header" :src="httpImageToPrefix(item.avatar)"  alt="" /> -->
            <div class="group-name">{{ item.username }}</div>
          </div>
          
        </div>

        <div  @click="showAllMembersChange()" class="group-number">
            {{ $getStringObj.getString($Strings.Mind_Group_Altogether) }}{{ checkGroupDataDetail.members.length }}
            <a-icon v-if="this.showAllMembers == false" type="right" />
            <a-icon v-else type="down" />
        </div>
      </div>
      <!-- v-if="checkGroupDataDetail.gmapCount > 0" -->
      <div  class="group-mind background-dom" >
          <div class="gruop-secondary-title">{{ $getStringObj.getString($Strings.Mind_Group_Mind_Mapping) }}</div>
          <template v-if="checkGroupDataDetail.gmaps != null && checkGroupDataDetail.gmaps.length > 0 ">
            <div v-if="checkGroupDataDetail.gmapCount > 0" class="group-mind-content"  >
                <div v-for="(item,index) in checkGroupDataDetail.gmaps"  @click="openMind(item)" :key="index"  class="group-mind-detail">
                    <img  v-real-img="httpImageToPrefix(item.cover)" src="../../assets/img/user/plate_plan_4.jpg" class="mind-img" alt="">
                    <div class="group-mind-name">
                        {{ item.title }}
                    </div>
                </div>
            </div>
          </template>
          <template v-else>
            <div class="null-mind-data">
              <img class="null-mind-data-img" src="../../assets/img/groupImg/nullMind.svg" alt="">
              <div class="null-mind-right-box">
                <div class="null-mind-right-box-title">
                  {{ $getStringObj.getString($Strings.Mind_Group_No_Content) }}
                </div>
                <div @click="showAllMindChange()" class="null-mind-right-box-button">
                  {{ $getStringObj.getString($Strings.Mind_Group_Immediately_Create) }}
                </div>
              </div>
            </div>
          </template>

          <div @click="showAllMindChange()" class="group-number">
            {{ $getStringObj.getString($Strings.Mind_Group_Altogether) }}{{ checkGroupDataDetail.gmapCount }}
            <a-icon  type="right"  />
          </div>
      </div>
      <div class="bg-placeholder"></div>
      <div class="group-comment background-dom" >
        <div class="gruop-secondary-title">{{ $getStringObj.getString($Strings.Mind_Group_Message_Area) }}</div>
        <div v-if="checkGroupDataDetail.gcommentCount > 0" class="group-comment-content">
          <div class="storm-user-comment" v-if="checkGroupDataDetail.gcommentCount > 0">
            <div class="storm-user-comment-message" v-for="(item, index) in checkGroupDataDetail.gcomments"
              :key="index">
              <div class="storm-user-comment-box">
                <div class="storm-user-header">
                  <div v-if="item.isMember" class="storm-user-img-ismember">
                    <img :src="httpImageToPrefix(item.userAvatar)" alt="" />
                  </div>
                  <div v-else class="storm-user-img">
                    <img :src="httpImageToPrefix(item.userAvatar)"
                      onerror="this.src='/static/img/userData/avatar_yellow_man.png'" alt="" />
                  </div>
                </div>
                <div class="storm-comment-content">
                  <div class="comment-content-header">
                    <div class="comment-user-name">
                      <span>{{ item.username }}</span>
                    </div>
                    <div class="comment-cotnent-detials" v-if="false">
                      <div class="chips-details-single" >
                        <img src="../../../static/img/share/fabulous.svg" alt="" />
                        <span></span>
                      </div>
                      <div class="chips-details-single" >
                        <img src="../../../static/img/userData/news_logo.png" alt="" />
                        <span></span>
                      </div>
                    </div>
                  </div>

                  <div class="comment-content-reply">
                    <div class="comment-time">
                      {{ formatTime(item.createTime) }}
                    </div>
                  </div>
                  <div class="comment-content-box">
                    <div class="comment-content-text">
                      <span>{{ item.content }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="storm-comment-reply" v-if="checkGroupDataDetail.gcomments[index].topComments">
                <div class="storm-user-comment-message-reply"
                  v-for="(itemReply, replyIndex) in checkGroupDataDetail.gcomments[index].topComments"
                  :key="replyIndex">
                  <div class="storm-user-header">
                    <div v-if="itemReply.isMember" class="storm-user-img-ismember">
                      <img :src="httpImageToPrefix(itemReply.userAvatar)" alt="" />
                    </div>
                    <div v-else class="storm-user-img">
                      <img :src="httpImageToPrefix(itemReply.userAvatar)"
                        onerror="this.src='/static/img/userData/avatar_yellow_man.png'" alt="" />
                    </div>
                  </div>
                  <div class="storm-comment-content">
                    <div class="comment-content-header">
                      <div class="comment-user-name">
                        <span>{{ itemReply.username }}</span>
                      </div>
                      <div class="comment-cotnent-detials">
                        <div class="chips-details-single" >
                          <img src="../../../static/img/share/fabulous.svg" alt="" />
                          <span></span>
                        </div>
                        <div class="chips-details-single" >
                          <img src="../../../static/img/userData/news_logo.png" alt="" />
                          <span></span>
                        </div>
                      </div>
                    </div>

                    <div class="comment-content-reply">
                      <div class="comment-time">
                        {{ formatTime(itemReply.createTime) }}
                      </div>
                    </div>
                    <div class="comment-content-box">
                      <div class="comment-content-text">
                        <span>{{ itemReply.content }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section class="group-comment-empty" v-else>

          <span>{{ $getStringObj.getString($Strings.Mind_Group_No_Message) }}</span>
          <label  @click="seeMore">{{ $getStringObj.getString($Strings.Mind_Group_Leave_Message) }}</label>
          <span>{{ $getStringObj.getString($Strings.Mind_Group_Bar) }}</span>
        </section>
        <div class="group-number" v-if="checkGroupDataDetail.gcommentCount > 0" style="cursor: pointer;" @click="seeMore">
         {{ $getStringObj.getString($Strings.Mind_Group_See_More) }}
          <a-icon type="right"  />
        </div>
      </div>
    </template>
    <template v-else-if="showFile == true" >
      <GroupFile :groupDataDetail="checkGroupDataDetail"
      :fullWidth="fullWidth" :messageArea="messageArea" :fullHeight="fullHeight" @hiddenFilePage="hiddenFilePage" />
    </template>
    <GroupMembersModal :groupDataDetail="checkGroupDataDetail" :show="showJoinModal" @hidden="hidden" @hiddenUpgradeMemberLimit="hiddenUpgradeMemberLimit" />
    <GroupInviteRembers :groupDataDetail="checkGroupDataDetail" :show="showInput" @hiddenInput="hiddenInput"  />
    <GroupShareModal
        :show="showAlter" @hiddenShare="hiddenShare"  />
  </div>
</template>

<script>
import httpImageToPrefix from '../../utils/httpImageToPrefix';
import { mapMutations } from "vuex";
import GroupMembersModal from "./groupMembersModal/GroupMembersModal";
import GroupFile from "./groupFile/GroupFile";
import dataTimeToFormats from "../../utils/timestampToTime";

import GroupShareModal from './groupShare/GroupShareModal';

import HeaderIcon from "../headerIcon/HeaderIcon"
import GroupInviteRembers from "./groupMembersModal/GroupInviteRembers";//邀请新成员输入框
if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer } = window.require("electron");
  // var ipcRenderer = window.ipcRenderer
}
export default {
  components: {
    GroupMembersModal,
    GroupFile,
    GroupShareModal,
    HeaderIcon,
    GroupInviteRembers,
  },
  props: [
    'checkGroupData',
    'checkGroupDataDetail',
    'GroupMembersModal',
    'fullHeight',
    'fullWidth',
  ],
  data() {
    return {
      showNotice: false,
      showAllMembers: false,
      showAllMind: false,
      showJoinModal: false,
      showPageType: 'default',
      showFile: true,//显示文件列表
      allowShowButton: false, //当前的公告内容是否多到可以展示展开按钮。
      showAlter:{showShare:false},
      sheraList: null,
      messageArea:'',
      isElectron: false, //是否时electron环境
      showInput: false,//邀请新成员

    }
  },
  created() {
      this.bus.$on("showFilePage", (msg) => {
         this.showFile = msg;
      });
      this.isElectron = this.$tools.isElectron();
  },
  mounted() {
    const groupDtail = this.$refs.groupDtail;
    if (groupDtail) {
      // groupDtail.style.height = window.getComputedStyle(groupDtail).height -80+'px'
    }
  },
  methods: {
    ...mapMutations([
        "setGroupDataDetail",
        "setRefreshMyGroupList",
        "changeGroupDataDetail",
        "setGroupDataDetailParameter",
        "showUserDataModel",
    ]),

    openMind(item) {
        if (this.isElectron) {
          ipcRenderer.send("openCalendarWindow", "/gmap?id=" + item.id);
        } else {
          const newWindow = window.open();
          const routerData = this.$router.resolve({
            path: "/gmap",
            query: {
              id: item.id,
            },
          });
          newWindow.location = routerData.href;
        }
    },

    copyGroupId() {
      this.copyToClip(this.checkGroupDataDetail.code)
    },
    copyToClip(content, message) {
        var aux = document.createElement("input");
        aux.setAttribute("value", content);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
        if (message == null) {
            this.$message.success(this.$getStringObj.getString(this.$Strings.Mind_Group_The_Group_Number_Was_Copied_Successfully))
        } else{
          this.$message.success(message)
        }
    },

    showAllMindChange() {
      this.messageArea = '';
      this.showFile = true;

    },
    hiddenFilePage(obj) {
      this.showFile = obj.show;
      if(obj.usedSpaceUpgradeName == this.$getStringObj.getString(this.$Strings.Used_Space_Upgrade)){
          this.hiddenUpgradeStorageSpace();
      }
    },
    clickSetting() {
      this.setGroupDataDetail(this.checkGroupDataDetail);
    },
    httpImageToPrefix(src) {
        return httpImageToPrefix(src)
    },
    showNoticeChange() {
      this.showNotice = !this.showNotice;
    },
    showAllMembersChange() {
      this.showJoinModal = true

    },
    //隐藏输入框 邀请新成员回调
    hiddenInput(obj) {
            if (obj.show == false) {
                if (obj.confrim == true) {
                }
                this.showInput = false;
            }
        },
    invitationNewMembers(){
        this.showInput = true;
    },
    //分享回调
    hiddenShare(obj){
      if (obj.show == false) {
                if (obj.confrim == true) {
                }
                this.showAlter.showShare = false;
                
            }
    },
    //分享
    clickShare(){
      let obj;
          obj = {
            sheraList:this.checkGroupDataDetail,
            showShare: true,
          }
          this.showAlter = obj;
    },
    nowJoinGroup() {
      this.showJoinModal = true
    },
    hidden(obj) {
        if (obj.show == false){
            this.showJoinModal =false
            this.setRefreshMyGroupList()
        }
    },
    //升级组成员上限回调
    hiddenUpgradeMemberLimit(obj) {
        if (obj.show == false){
            this.showJoinModal =false;

            var rootDirectory = this.$getStringObj.getString(this.$Strings.Mind_Group_Maximum_Number_Of_Shared_Group_Members);

            this.setGroupDataDetailParameter({data:this.checkGroupDataDetail,rootDirectory:rootDirectory});
        }
    },
    //升级组存储上限回调
    hiddenUpgradeStorageSpace() {
            var rootDirectory = this.$getStringObj.getString(this.$Strings.Used_Space_Upgrade);

            this.setGroupDataDetailParameter({data:this.checkGroupDataDetail,rootDirectory:rootDirectory});
    },
    setNoticeBox(dom) {
      this.allowShowButton = false
      if( dom == null) {
        return
      }
      let height = dom.clientHeight
      // console.log('dom',height);
      if (height > 50) {
        this.allowShowButton = true;
      }
    },
    //时间处理
    formatTime(time) {
      return dataTimeToFormats.formatTime(time);
    },
    seeMore(){
      this.messageArea = 'messageArea';
      this.showFile = true;
      // console.log(this.messageArea);
    },
     //用户中心
    getUserProfile(id) {
      this.showUserDataModel({
        accout: true,
        userId: id,
        xfrom: "crisps",
        fromId: id,
      });
    },
  },
  watch: {
    checkGroupDataDetail: {
      handler(newVal, oldVal) {
        this.showNotice = false;
        this.$nextTick((_) => {
          //code
          this.setNoticeBox(this.$refs.groupNoticeContent);
          // console.log('获取',this.$refs.complieheadermenubox.getBoundingClientRect());
        });

      },
      deep: true,
      immediate: true,
    },
    // showFilePage:{
    //   handler(newVal, oldVal){
    //     this.showFile = newVal;
    //   }
    // }
  }
};
</script>

<style lang="less" scoped>
.group-member-detail:hover {
  background-color: #eee;
}
.group-detail {
  background: #EFEFEF;
  // background-color: #fbfbfb;
  // min-height: calc(100vh - 80px);
  .background-dom {
    background-color: #fff;
  }
  .group-detail-header {
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 20px;
    padding-top: 30px;
    padding-bottom: 25px;
    padding-right: 20px;
    border-bottom: 1px solid #fff;
    .group-detail-header-img {
        width: 88px;
        height: 88px;
        border-radius: 4px;
    }
    .group-detail-header-right {
        margin-left: 10px;
        .group-detail-title {
            font-size: 16px;
            color: #333;
            height: 22px;
            line-height: 22px;
            font-weight: bold;
        }
        .group-detail-id {
            font-size: 12px;
            color: #FF4220;
            cursor:pointer;
            margin-top: 1px;
        }
        .group-detail-introductory {
            font-size: 12px;
            color: #999;
            margin-top: 1px;

        }
        .group-detail-button-box {
          margin-top: 3px;
            .group-botton-class {
                margin-right: 20px;
                display: inline-block;
                color: #999;
                font-size: 14px;
                padding: 3px 16px;
                border-radius: 25px;
                background-color: #EEEEEE;
            }
        }
    }
    .group-operation {
        position: absolute;
        right:20px;
        bottom: 20px;
        img {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
        .img-margin {
            margin-right: 20px;
        }
    }
  }
    .gruop-secondary-title {
        padding-bottom: 10px;
        font-size: 14px;
        color: #333;
        font-family: PingFang SC;
        font-weight: bold;
    }
    .group-number {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }
  .group-notice {
      padding-top: 20px;
      padding-left: 20px;
      padding-bottom: 20px;
      padding-right: 20px;
      border-bottom: 1px solid #fff;
      background-color: #fff;
      position: relative;
      top: 10px;

      .group-notice-content-father {
        position: relative;
        .group-notice-content {
            font-size: 12px;
            color: #999;
            word-wrap: break-word;
        }
        .beyondHidden {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient:vertical;
            -webkit-line-clamp:3;
            // max-height: 70px;
        }

        .click-unfold {
            position: absolute;
            right: 0;
            bottom: 0;
            color: #FB6F57;
            font-size: 14px;
            display: inline-block;
            cursor: pointer;
            // padding-left: 40px;
            display: flex;
            .left-linear-gradient {
              width: 20px;
              background-image: linear-gradient(to right,rgba(255,255,255,0), #F0F2F8);
            }
            .click-unfold-text {
              background-color:#F0F2F8;
              margin-right: 10px;
            }
        }
      }
  }
  .group-member {
        padding: 20px;
        border-bottom: 1px solid #fff;
        position: relative;
        padding-bottom: 20px;
        background-color: #fff;
        position: relative;
        top: 20px;
        .group-member-content {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            height: 108px;
            overflow: hidden;
            .group-member-detail {
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 20px;
                cursor: pointer;
                  .group-user-header {
                      width: 56px;
                      height: 56px;
                      border-radius: 50%;
                      display: block;
                      margin: 0 auto;
                  }
                  .group-name{
                      margin-top: 10px;
                      font-size: 12px;
                      text-align:center;
                      color: #666666;
                      max-width: 71px;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                  }
                  .groupInvitation-user {
                      // border: 1px solid #FFCA69;
                      position: absolute;
                      left: 50%;
                      bottom: 12px;
                      transform: translate(-50%,0);
                      z-index: 1;
                      overflow: hidden;
                      border-radius: 50%;
                  }  
            }
        }
        .beyondCoverShow {
            overflow: visible;
            height: auto;
        }
  }
  .group-mind {
        padding: 20px;
        padding-bottom: 25px;
        border-bottom: 1px solid #fff;
        background-color: #fff;
        position: relative;
        top: 30px;

        .group-mind-content {
            display: flex;
            flex-wrap: wrap;
            height: 143px;
            overflow: hidden;
            .group-mind-detail{
              margin-bottom: 20px;
              padding-top: 5px;
              cursor: pointer;
                .mind-img {
                    display: block;
                    width: 108px;
                    height: 108px;
                    margin-bottom: 12px;
                    margin-left: 15px;
                    margin-right: 15px;
                    border-radius: 4px;
                    box-shadow: 0 0 5px  #ccc;
                    &:hover{
                      transition: 0.3s;
                      transform: scale(1.1);
                    }
                }
                .group-mind-name {
                    font-size: 12px;
                    text-align:center;
                    color: #666666;
                    max-width: 147px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                
            }
        }

        .null-mind-data {
          // width: 316px;
          height: 150px;
          margin: 0 auto;
          // background-color: hotpink;
          display: flex;
          justify-content: center;
          align-items: center;
          .null-mind-data-img {
            margin-right: 60px;
          }
          .null-mind-right-box {
            // width: 88px;
              .null-mind-right-box-title {
                text-align: center;
                color: #C5C5C5;
                margin-bottom: 17px;
                font-size: 14px;
              }
              .null-mind-right-box-button {
                height: 32px;
                line-height: 32px;
                display: inline-block;
                border-radius: 16px;
                font-size: 16px;
                color: #fff;
                padding: 0 16px;
                background-color: #FD492B;
                cursor: pointer;
              }
          }
        }
        // .group-mind-content-all-show {
        //     overflow: visible;
        //     height: auto;
        // }
  }
  .bg-placeholder{
    width: auto;
    height: 10px;
    background: #EFEFEF;
    position: relative;
    top: 30px;
  }
  .group-comment {
    padding: 20px;
    border-bottom: 1px solid #fff;
    background-color: #fff;
    position: relative;
    top: 30px;
    // background-color: #F0F2F8;
    // background-color: #f5f5f5;
    .group-comment-empty {
      padding: 20px 0 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 14px;
        color: #C5C5C5;
      }

      label {
        font-size: 16px;
        color: #FD492B;
        cursor: pointer;
      }
    }
    .storm-user-comment {
      overflow: auto;
      width: 100%;

      //评论
      .storm-user-comment-message {
        .storm-user-comment-box {
          display: flex;
          min-height: 109px;
          border-bottom: 1px solid #ececec;
          align-items: center;
          flex-wrap: nowrap;

          .storm-user-header {
            width: 42px;
            height: 80px;
            margin-bottom: 12px;
            // cursor: pointer;

            .storm-user-img {
              border: 1px solid #ffffff;
              width: 42px;
              height: 42px;
              border-radius: 50%;

              img {
                width: 42px;
                height: 42px;
                border-radius: 50%;
                margin-top: 12px;
              }
            }

            .storm-user-img-ismember {
              border: 1px solid #ffffff;
              border-radius: 50%;
              background-image: url(../../../static/img/userData/user_message_vip_bg.png);
              background-size: 100%;
              width: 42px;
              height: 52px;

              img {
                width: 42px;
                height: 42px;
                border-radius: 50%;
                margin-top: 12px;
              }
            }
          }

          .storm-comment-content {
            // min-width: 322px;
            width: 100%;
            margin-left: 10px;

            .comment-content-header {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .comment-user-name {
                font-size: 12px;
                font-family: Noto Sans SC;
                font-weight: inherit;
                line-height: 17px;
                color: #333333;
                opacity: 1;
                max-height: 20px;

                span {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: 60px;
                }

                .comment-author-logo {
                  margin-left: 26px;
                  padding: 3px 6px;
                  border-radius: 2px;
                  background: #ec705a;
                  color: #ffffff;
                }
              }

              .comment-cotnent-detials {
                .chips-details-single {
                  float: left;
                  margin-left: 20px;
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                }
              }
            }

            .comment-content-box {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 6px;

              .comment-content-text {
                width: 266px;
                font-size: 14px;
                font-family: Noto Sans SC;
                font-weight: 400;
                line-height: 20px;
                color: #333333;
                opacity: 1;
              }
            }

            .comment-content-reply {
              display: flex;
              justify-content: space-between;

              .comment-time {
                font-size: 12px;
                font-family: Noto Sans SC;
                font-weight: 400;
                line-height: 17px;
                color: #999999;
                opacity: 1;
              }

              .comment-reply {
                cursor: pointer;
                margin: 0;
                font-size: 12px;
                font-family: Noto Sans SC;
                /*font-weight: bold;*/
                line-height: 17px;
                color: #666666;
                opacity: 1;
              }
            }
          }
        }
      }

      //回复
      .storm-comment-reply {
        border-bottom: 1px solid #ececec;
        padding: 0 0 20px 0;

        .storm-user-comment-message-reply {
          display: flex;
          align-items: center;
          margin-left: 50px;

          .storm-user-header {
            width: 42px;
            height: 80px;
            margin-bottom: 12px;
            cursor: pointer;

            .storm-user-img {
              border: 1px solid #ffffff;
              width: 42px;
              height: 42px;
              border-radius: 50%;

              img {
                width: 42px;
                height: 42px;
                border-radius: 50%;
                margin-top: 12px;
              }
            }

            .storm-user-img-ismember {
              border: 1px solid #ffffff;
              border-radius: 50%;
              background-image: url(../../../static/img/userData/user_message_vip_bg.png);
              background-size: 100%;
              width: 42px;
              height: 52px;

              img {
                width: 42px;
                height: 42px;
                border-radius: 50%;
                margin-top: 12px;
              }
            }
          }

          .storm-comment-content {
            // min-width: 270px;
            width: 100%;
            margin-left: 10px;

            .comment-content-header {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .comment-user-name {
                font-size: 12px;
                font-family: Noto Sans SC;
                font-weight: inherit;
                line-height: 17px;
                color: #333333;
                opacity: 1;
                max-height: 20px;

                span {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: 60px;
                }

                .comment-author-logo {
                  margin-left: 26px;
                  padding: 3px 6px;
                  border-radius: 2px;
                  background: #ec705a;
                  color: #ffffff;
                }
              }

              .comment-cotnent-detials {
                .chips-details-single {
                  float: left;
                  margin-left: 20px;
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                }
              }
            }

            .comment-content-box {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 6px;

              .comment-content-text {
                width: 266px;
                font-size: 14px;
                font-family: Noto Sans SC;
                font-weight: 400;
                line-height: 20px;
                color: #333333;
                opacity: 1;
              }
            }

            .comment-content-reply {
              display: flex;
              justify-content: space-between;

              .comment-time {
                font-size: 12px;
                font-family: Noto Sans SC;
                font-weight: 400;
                line-height: 17px;
                color: #999999;
                opacity: 1;
              }

              .comment-reply {
                cursor: pointer;
                margin: 0;
                font-size: 12px;
                font-family: Noto Sans SC;
                /*font-weight: bold;*/
                line-height: 17px;
                color: #666666;
                opacity: 1;
              }
            }
          }

          //回复
          // .comment-reply-from{
          //   width: 100%;
          //   height: 50px;
          //   border: 1px solid red;
          //   margin: 0 13px;
          // }
        }

        .comment-reply-more {
          display: flex;
          margin-left: 50px;
          font-size: 12px;
          font-family: Noto Sans SC;
          font-weight: 400;
          line-height: 17px;
          color: #666666;
          opacity: 1;
          cursor: pointer;
          align-items: center;

          .more-comment-logo {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 6px solid #666666;
            border-radius: 3px;
            margin-left: 6px;
          }
        }
      }
    }
  }


}
</style>
