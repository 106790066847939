<template>
    <div @click.stop="hidden(false)" v-show="showModal" class="group-add-new-group-box">
        <div @click.stop="hidden(true)" class="center-writer">
            <div class="group-setting-administrator-header">
                <!-- <div class="administrator-header-left">
                </div> -->
                <div v-if="gropuMembers !=null&&gropuMembers.memberCount !=null" class="administrator-header-main">
                    {{this.$getStringObj.getString(this.$Strings.Mind_Group_Shared_Group_Membership)}}
                    ({{ gropuMembers.memberCount }}/{{ gropuMembers.memberLimit}})
                </div>
                <div v-else class="administrator-header-main">
                    {{this.$getStringObj.getString(this.$Strings.Mind_Group_Shared_Group_Membership)}}
                </div>
                <div  class="administrator-header-right">
                    <img style="cursor: pointer; margin-right: 10px" @mouseover="moveMenu($event,true)" @mouseout="moveMenu($event,false)" src="../../../assets/img/groupImg/more.svg" width="20" alt="">
                    <img class="hidden-icon" @click.stop="hidden(false)" src="../../../assets/img/popupsImg/colse_new.svg" alt="">
                </div>
            </div>
            <div class="search-header-input">
                <div class="search-left-search">
                    <a-button
                    class="search-search-button"
                    shape="circle"
                    icon="search"
                    size="small"
                    @click="searchButton()"
                    />
                    <input
                    class="search-search-box"
                    type="text"
                    v-model="searchText"
                    v-on:keyup.enter="searchButton()"
                    :placeholder="$getStringObj.getString($Strings.Mind_Group_Members_Of_The_Search)"
                    />
                    <div v-if="searchText != ''" class="cleariconfont search-right-icon" @click="clearSearchText()">&#xe605;</div>
                </div>
            </div>
            <template v-if="isAdminType == 'admin'">
                <div v-if=" gropuMembers != null && gropuMembers.admins != null"  class="group-members-big-box">
                    <div class="group-members-title">
                        {{this.$getStringObj.getString(this.$Strings.Mind_Group_Creator)}}、{{this.$getStringObj.getString(this.$Strings.Mind_Group_Administrator)}}（{{ gropuMembers.adminCount }}{{this.$getStringObj.getString(this.$Strings.Mind_Group_Number_Of_People)}}）
                    </div>
                    
                    <div id="group-members-big-box-children" class="group-members-big-box-children">
                          <div class="members-each" @click.stop="checkMenuItem($getStringObj.getString($Strings.Mind_Group_Invite_New_Members))" style=" cursor : pointer;z-index: 3;position: absolute;width:96%;">
                                <div class="members-each-left" >
                                        <div class="groupInvitation-user-box" >
                                            <img class="groupInvitation-user" src="../../../assets/img/groupImg/groupInvitation.svg"/>
                                            <!--<div class="" @click.stop="checkMenuItem($getStringObj.getString($Strings.Mind_Group_Invite_New_Members))" 
                                                :style="{
                                                        position: 'absolute',
                                                        left: '50%',
                                                        bottom: '1px',
                                                        transform: 'translate(-50%,0)',
                                                        width: 40 + 'px',
                                                        height: 40 + 'px',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center center',
                                                        overflow: 'hidden',
                                                        borderRadius: '50%',
                                                        backgroundImage:'url('+require('../../../assets/img/groupImg/groupInvitation.svg')+')',
                                                        cursor : 'pointer',
                                                    }"
                                            ></div> -->
                                        </div>
                                    <div class="invitation-group" @click.stop="checkMenuItem($getStringObj.getString($Strings.Mind_Group_Invite_New_Members))" style=" cursor : pointer;">
                                        {{this.$getStringObj.getString(this.$Strings.Mind_Group_Invite_New_Members)}}
                                    </div>
                                </div>
                            </div>
                        <div class="group-members-big-box-children-content" id="group-members-big-box-children-content"
                         style="position: absolute;margin-top: 72px;overflow-y: auto;height: 168px;width: 96%;">
                          
                            <div v-for="(item,index) in gropuMembers.admins " :key="index" class="members-each"  @click="getUserProfile(item.id)" >
                                <div class="members-each-left">
                                        <div :style="{position: 'relative', top:(item.isMember?6:0)+'px', height: 40 + 'px', width: 40 + 'px', margin: '0 auto', marginRight:'8px', }" >
                                            <img 
                                                v-if="item.isMember"
                                                :style="{
                                                    position: 'absolute',
                                                    zIndex: 1,
                                                    left: 50 + '%',
                                                    transform: 'translate(-50%,0)',
                                                    top: -13 + 'px',
                                                    width: 24 + 'px',
                                                    height: 16 + 'px',
                                                }"
                                                src="../../../assets/img/groupImg/crown.svg"
                                                alt
                                                />
                                            <div 
                                                :style="{
                                                        position: 'absolute',
                                                        left: '50%',
                                                        bottom: '1px',
                                                        transform: 'translate(-50%,0)',
                                                        width: 40 + 'px',
                                                        height: 40 + 'px',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center center',
                                                        overflow: 'hidden',
                                                        borderRadius: '50%',
                                                        backgroundImage: 'url('+ httpImageToPrefix(item.avatar) +')',
                                                    }"
                                            ></div>
                                        </div>
                                    <div>
                                        {{ item.username }}
                                    </div>
                                </div>
                                <div v-if="item.type == 2 "   class="members-each-right">
                                    {{$getStringObj.getString($Strings.Mind_Group_Creator)}}
                                </div>
                                <div v-else-if="item.type == 1 "   class="members-each-right">
                                    {{$getStringObj.getString($Strings.Mind_Group_Administrator)}}
                                </div>
                                <div v-else-if="item.type == 0 "   class="members-each-right">
                                    {{$getStringObj.getString($Strings.Mind_Group_Member)}}
                                </div>
                            </div>
                        </div>
                        <div style="clear:both;"></div>
                    </div>
                </div>
            </template>
            <template v-if="isAdminType == 'searchAdmin'">
                <div v-if=" searchAdminListData != null" class="group-members-big-box">
                    <div  class="group-members-big-box-children group-members-big-box-children-content">
                        <div v-for="(item,index) in searchAdminListData " :key="index" class="members-each">
                            <div class="members-each-left">
                                    <div :style="{position: 'relative', height: 40 + 'px', width: 40 + 'px', margin: '0 auto', marginRight:'8px', }" >
                                        <img 
                                            v-if="item.isMember"
                                            :style="{
                                                position: 'absolute',
                                                zIndex: 1,
                                                left: 50 + '%',
                                                transform: 'translate(-50%,0)',
                                                top: -13 + 'px',
                                                width: 24 + 'px',
                                                height: 16 + 'px',
                                            }"
                                            src="../../../assets/img/groupImg/crown.svg"
                                            alt
                                            />
                                        <div 
                                            :style="{
                                                    position: 'absolute',
                                                    left: '50%',
                                                    bottom: '1px',
                                                    transform: 'translate(-50%,0)',
                                                    width: 40 + 'px',
                                                    height: 40 + 'px',
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center center',
                                                    overflow: 'hidden',
                                                    borderRadius: '50%',
                                                    backgroundImage: 'url('+ httpImageToPrefix(item.avatar) +')',
                                                }"
                                        ></div>
                                    </div>
                                <div>
                                    {{ item.username }}
                                </div>
                            </div>
                            <div v-if="item.type == 2 "   class="members-each-right">
                                {{$getStringObj.getString($Strings.Mind_Group_Creator)}}
                            </div>
                            <div v-else-if="item.type == 1 "   class="members-each-right">
                                {{$getStringObj.getString($Strings.Mind_Group_Administrator)}}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else-if="isAdminType == 'rember'">
                <div v-if=" gropuMembers != null && gropuMembers.admins != null" class="group-members-big-box">
                    <div  id="group-members-big-box-children-del" class="group-members-big-box-children">
                        <div class="group-members-big-box-children-content" id="group-members-big-box-children-content-del">
                            <div v-for="(item,index) in gropuMembers.admins " :key="index" @click="checkDelet(item,index)" class="members-each">
                                <div class="members-each-left">
                                    <template v-if="item.type != 2">
                                        <div v-if="item.isNeedDelet  == false" class="default-circle" >
                                        </div>
                                        <img v-else class="check-icon" src="../../../assets/img/groupImg/check-delet.png" alt="">
                                    </template>
                                    <div :style="{position: 'relative', height: 40 + 'px', width: 40 + 'px', margin: '0 auto', marginRight:'8px', }" >
                                        <img 
                                            v-if="item.isMember"
                                            :style="{
                                                position: 'absolute',
                                                zIndex: 1,
                                                left: 50 + '%',
                                                transform: 'translate(-50%,0)',
                                                top: -13 + 'px',
                                                width: 24 + 'px',
                                                height: 16 + 'px',
                                            }"
                                            src="../../../assets/img/groupImg/crown.svg"
                                            alt
                                            />
                                        <div 
                                            :style="{
                                                    position: 'absolute',
                                                    left: '50%',
                                                    bottom: '1px',
                                                    transform: 'translate(-50%,0)',
                                                    width: 40 + 'px',
                                                    height: 40 + 'px',
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center center',
                                                    overflow: 'hidden',
                                                    borderRadius: '50%',
                                                    backgroundImage: 'url('+ httpImageToPrefix(item.avatar) +')',
                                                }"
                                        ></div>
                                    </div>
                                    <div>
                                        {{ item.username }}
                                    </div>
                                </div>
                                <div v-if="item.type == 2 "   class="members-each-right">
                                    {{$getStringObj.getString($Strings.Mind_Group_Creator)}}
                                </div>
                                <div v-else-if="item.type == 1 "   class="members-each-right">
                                    {{$getStringObj.getString($Strings.Mind_Group_Administrator)}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="group-members-footer-menu">
                    <div class="check-rembers-box">
                        {{$getStringObj.getString($Strings.Mind_Group_Selected)}}{{ this.deleGroupRemberIdList.length }}{{$getStringObj.getString($Strings.Mind_Group_Member)}}
                    </div>
                    <div class="all-click-button-box">
                        <!-- <span @click="allClickCheck()">
                            {{$getStringObj.getString($Strings.Mind_Group_Check_All)}}
                        </span> -->
                        <span @click="removeMembersButton()">
                            {{$getStringObj.getString($Strings.Mind_Group_Remove)}}
                        </span>
                        <span @click="cancelDelet()">
                            {{$getStringObj.getString($Strings.Mind_Group_Cancel)}}
                        </span>
                    </div>
                </div>
            </template>
            <template v-else-if="isAdminType == 'searchRember'">
                <div class="group-members-big-box">
                    <div v-if="searchRembersListData != null" class="group-members-big-box-children">
                        <div v-for="(item,index) in searchRembersListData " :key="index" @click="checkSearchDelet(item,index)" class="members-each">
                            <div class="members-each-left">
                                <template v-if="item.type != 2">
                                    <div v-if="item.isNeedDelet  == false" class="default-circle" >
                                    </div>
                                    <img v-else class="check-icon" src="../../../assets/img/groupImg/check-delet.png" alt="">
                                </template>
                                    <div :style="{position: 'relative', height: 40 + 'px', width: 40 + 'px', margin: '0 auto', marginRight:'8px', }" >
                                        <img 
                                            v-if="item.isMember"
                                            :style="{
                                                position: 'absolute',
                                                zIndex: 1,
                                                left: 50 + '%',
                                                transform: 'translate(-50%,0)',
                                                top: -13 + 'px',
                                                width: 24 + 'px',
                                                height: 16 + 'px',
                                            }"
                                            src="../../../assets/img/groupImg/crown.svg"
                                            alt
                                            />
                                        <div 
                                            :style="{
                                                    position: 'absolute',
                                                    left: '50%',
                                                    bottom: '1px',
                                                    transform: 'translate(-50%,0)',
                                                    width: 40 + 'px',
                                                    height: 40 + 'px',
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center center',
                                                    overflow: 'hidden',
                                                    borderRadius: '50%',
                                                    backgroundImage: 'url('+ httpImageToPrefix(item.avatar) +')',
                                                }"
                                        ></div>
                                    </div>
                                <div>
                                    {{ item.username }}
                                </div>
                            </div>
                            <div v-if="item.type == 2 "   class="members-each-right">
                                {{$getStringObj.getString($Strings.Mind_Group_Creator)}}
                            </div>
                            <div v-else-if="item.type == 1 "   class="members-each-right">
                                {{$getStringObj.getString($Strings.Mind_Group_Administrator)}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="group-members-footer-menu">
                    <div class="check-rembers-box">
                        {{$getStringObj.getString($Strings.Mind_Group_Selected)}}{{ searchDeleGroupRemberIdList.length }}{{$getStringObj.getString($Strings.Mind_Group_Member)}}
                    </div>
                    <div class="all-click-button-box">
                        <!-- <span @click="allSearchClickCheck()">
                            {{$getStringObj.getString($Strings.Mind_Group_Check_All)}}
                        </span> -->
                        <span @click="removeMembersButton()">
                            {{$getStringObj.getString($Strings.Mind_Group_Remove)}}
                        </span>
                        <span @click="cancelDelet()">
                            {{$getStringObj.getString($Strings.Mind_Group_Cancel)}}
                        </span>
                    </div>
                </div>
            </template>
        </div>
        <GroupMembersModalMenu :showData="menuList" :pointObj="pointObj" @checkMenuItem="checkMenuItem" />
        <GroupInviteRembers :groupDataDetail="groupDataDetail" :show="showInput" @hiddenInput="hiddenInput"  />
        <GroupremoveMembersAlter :show="showAlter" @hiddenAlter="hiddenAlter"  />
    </div>
</template>

<script>
import { postGroupMembers, postGroupSearchGmembers, postGroupDeleteGmembers} from "../../../common/netWork/group_api";
import { mapMutations } from "vuex";

import httpImageToPrefix from '../../../utils/httpImageToPrefix';
import timestampToTime from "../../../utils/timestampToTime";
import GroupMembersModalMenu from "./GroupMembersModalMenu";
import GroupInviteRembers from "./GroupInviteRembers";
import GroupremoveMembersAlter from "./GroupremoveMembersAlter"
export default {
    components: {
        GroupMembersModalMenu,
        GroupInviteRembers,
        GroupremoveMembersAlter
    },
    props: [
        "show",
        "groupDataDetail",
    ],
    data() {
        return {
            showModal: false,
            searchText: '', //搜索的内容
            gropuMembers: null, //所有的组成员
            menuList: [
                {
                    name: this.$getStringObj.getString(this.$Strings.Mind_Group_Upgrade_Member_Limit),
                    // img:''
                },
                {
                    name: this.$getStringObj.getString(this.$Strings.Mind_Group_Invite_New_Members),
                },
                {
                    name: this.$getStringObj.getString(this.$Strings.Mind_Group_Delete_Members),
                }
            ],
            pointObj: {

            },
            isAdminType: 'admin', //admin 、 searchAdmin、 rember、 searchRember ,判断是管理员页面，还是管理员搜索，还是成员页面，还是成员搜索
            showInput: false, //是否展示邀请输入框
            deleGroupRemberIdList: [], //要删除的成员列表
            isAllDeletCheck:false,  //是否全选删除
            searchAdminListData: null, //搜索的管理员数据
            searchRembersListData: null, //搜索的除管理员以外所有人信息
            searchDeleGroupRemberIdList: [], //要删除的搜索成员列表
            isSearchAllDeletCheck:false,  //是否全选删除搜索成员列表。
            showAlter: false, //是否展示移除提示框
            isScroll: true, //当前是否可以拖动
        }
    },
    created() {
        // console.log('1',this.$getStringObj.getString(this.$Strings.Mind_Group_Invite_The_Successful));
        // console.log(this.$Strings.Mind_Group_Immediately_Create);
        // {{ this.$Strings.Mind_Group_Immediately_Create }}
    },
    mounted() {
    },
    beforeUpdate() {    //每次打开弹窗执行

    },
    methods: {
        ...mapMutations([
            "setgroupAdminMenu",
            "showUserDataModel",
        ]),

        scrollMoreData() {
            //滚动事件
            let scrollDom = document.getElementById("group-members-big-box-children");
            let child = document.getElementById("group-members-big-box-children-content");
            const scrollTopHeight = scrollDom.scrollTop ; //滚动高度
            const scrollHeight = scrollDom.clientHeight; //父盒子高度
            const childHeight = child.offsetHeight ; //网页可见区域高(包括边线的宽)
            if (
                scrollTopHeight + scrollHeight >= childHeight &&
                this.isScroll
            ) {
                this.isScroll = false;
                this.scrollPullList()
            }

        },

        scrollMoreDelData() {
            //滚动事件
            let scrollDom = document.getElementById("group-members-big-box-children-del");
            let child = document.getElementById("group-members-big-box-children-content-del");
            const scrollTopHeight = scrollDom.scrollTop ; //滚动高度
            const scrollHeight = scrollDom.clientHeight; //父盒子高度
            const childHeight = child.offsetHeight ; //网页可见区域高(包括边线的宽)
            if (
                scrollTopHeight + scrollHeight >= childHeight &&
                this.isScroll
            ) {
                this.isScroll = false;
                this.scrollPullList()

            }
        },

        
        scrollPullList() {
            this.pullGroupMembers({ groupId: this.groupDataDetail.id, lastId: this.gropuMembers.admins[this.gropuMembers.admins.length - 1].id }).then(res => {
                if (res != null && res.members != null) {
                    for (let index = 0; index < res.members.length; index++) {
                        res.members[index].isNeedDelet = false;
                    }
                    this.gropuMembers.admins = this.gropuMembers.admins.concat(res.members);
                }
            }).finally(res => {
                this.isScroll = true;
            })
        },

        removeMembersButton() {
            if (this.isAdminType == 'rember' && this.deleGroupRemberIdList.length == 0) {
                this.$message.warning(this.$getStringObj.getString(this.$Strings.Mind_Group_Please_Select_The_Member_To_Delete));
                return;
            }
            if (this.isAdminType == 'searchRember' &&this.searchDeleGroupRemberIdList.length == 0) {
                this.$message.warning(this.$getStringObj.getString(this.$Strings.Mind_Group_Please_Select_The_Member_To_Delete));
                return;
            }
            this.showAlter = true;
        },

        hiddenAlter(obj) {
            if (obj.show == false) {
                if (obj.confrim == true) {
                    this.clickRemoveMembers();
                } 
                this.showAlter = false;
                
            }
        },

        //取消删除
        cancelDelet() {
            //从成员界面进入管理员界面清空搜索内容。
            if (this.isAdminType == 'rember' || this.isAdminType == 'searchRember') {
                this.searchText = ''
            }
            this.isAdminType = 'admin'

            this.$nextTick(() => {
                let scrollDom = document.getElementById("group-members-big-box-children");
                if (scrollDom != undefined) {
                    scrollDom.removeEventListener("scroll", this.scrollMoreData); //解除绑定
                    scrollDom.addEventListener("scroll", this.scrollMoreData, false); //添加绑定
                }
            });
        },

        //搜索的全选删除
        allSearchClickCheck() {
            this.isSearchAllDeletCheck = !this.isSearchAllDeletCheck;
            if(this.isSearchAllDeletCheck) {  //全选
                this.searchDeleGroupRemberIdList = [];
                for (let index = 0; index < this.searchRembersListData.length; index++){
                    if(this.searchRembersListData[index].type == 2) {
                        continue;
                    }
                    this.searchRembersListData[index].isNeedDelet = true;
                    this.searchDeleGroupRemberIdList.push(this.searchRembersListData[index].id);
                }
                this.searchRembersListData.push(1);
                this.searchRembersListData.pop();

            } else { //取消全选
                for (let index = 0; index < this.searchRembersListData.length; index++){
                    if(this.searchRembersListData[index].type == 2) {
                        continue;
                    }
                    this.searchRembersListData[index].isNeedDelet = false;
                }
                this.searchRembersListData.push(1);
                this.searchRembersListData.pop();
                this.searchDeleGroupRemberIdList = [];
            }
        },

        //搜索的选择删除
        checkSearchDelet(item, index) {  
            //创建者不允许删除       
            if (item.type == 2) {
                return;
            }     
           let point =  this.searchDeleGroupRemberIdList.indexOf(item.id);
           this.searchRembersListData[index].isNeedDelet = !this.searchRembersListData[index].isNeedDelet;
           this.searchRembersListData.splice(index, 1, this.searchRembersListData[index]);
            if ( point > -1  ) {
                this.searchDeleGroupRemberIdList.splice(point,1);
            } else {
                this.searchDeleGroupRemberIdList.push(item.id)
            }
        },

        //全选删除
        allClickCheck() {
            this.isAllDeletCheck = !this.isAllDeletCheck;
            if(this.isAllDeletCheck) {  //全选
                this.deleGroupRemberIdList = []
                for (let index = 0; index < this.gropuMembers.admins.length; index++){
                    //不允许删除管理员
                    if(this.gropuMembers.admins[index].type == 2) {
                        continue;
                    }
                    this.gropuMembers.admins[index].isNeedDelet = true;
                    this.deleGroupRemberIdList.push(this.gropuMembers.admins[index].id);
                }
                this.gropuMembers.admins.push(1);
                this.gropuMembers.admins.pop();

            } else { //取消全选
                for (let index = 0; index < this.gropuMembers.admins.length; index++){
                    if(this.gropuMembers.admins[index].type == 2) {
                        continue;
                    }
                    this.gropuMembers.admins[index].isNeedDelet = false;
                }
                this.gropuMembers.admins.push(1);
                this.gropuMembers.admins.pop();
                this.deleGroupRemberIdList = [];
            }
        },

        //选择删除的
        checkDelet(item, index) {
            //创建者不允许删除       
            if (item.type == 2) {
                return;
            }
           let point =  this.deleGroupRemberIdList.indexOf(item.id);
           this.gropuMembers.admins[index].isNeedDelet = !this.gropuMembers.admins[index].isNeedDelet;
           this.gropuMembers.admins.splice(index, 1, this.gropuMembers.admins[index]);
            if ( point > -1  ) {
                this.deleGroupRemberIdList.splice(point,1);
            } else {
                this.deleGroupRemberIdList.push(item.id)
            }
        },

        //删除组成员。
        clickRemoveMembers() {
            if (this.isAdminType == 'rember') {  //删除组成员
                this.pushGroupDeleteGmembers({ groupId: this.groupDataDetail.id, userIds: this.deleGroupRemberIdList}).then(res => {
                    this.searchButton();
                    this.deleGroupRemberIdList = [];
                    this.isAllDeletCheck = false;
                })
            } else if (this.isAdminType == 'searchRember') { //删除搜索的组成员
                this.pushGroupDeleteGmembers({ groupId: this.groupDataDetail.id, userIds: this.searchDeleGroupRemberIdList}).then(res => {
                    this.searchButton();
                    this.searchDeleGroupRemberIdList = [];
                    this.isSearchAllDeletCheck = false;
                })
            }
        },
        //升级成员上限。
        upgradeMemberLimit() {
            // console.log("ok");
            this.hiddenUpgradeMemberLimit(false);
        },
        //点击升级组成员上限隐藏模态框及打开设置页面
        hiddenUpgradeMemberLimit(show) {
            this.showModal = show
                if (show == false) {
                let item = {
                    show: false
                }
                this.$emit("hiddenUpgradeMemberLimit", item);
            }
        },

        //隐藏输入框
        hiddenInput(obj) {
            if (obj.show == false) {
                if (obj.confrim == true) {
                }
                this.showInput = false;
            }
        },

        checkMenuItem (val) {
            if (val == this.$getStringObj.getString(this.$Strings.Mind_Group_Invite_New_Members)) {
                this.showInput = true;
            } else if (val == this.$getStringObj.getString(this.$Strings.Mind_Group_Delete_Members)) {
                //从管理员界面进入成员界面清空搜搜框
                if (this.isAdminType == 'admin' || this.isAdminType == 'searchAdmin') {
                    this.searchText = ''
                }
                this.isAdminType = 'rember'
                this.$nextTick(() => {
                    let scrollDelDom = document.getElementById("group-members-big-box-children-del");
                    if (scrollDelDom != undefined) {
                        scrollDelDom.removeEventListener("scroll", this.scrollMoreDelData); //解除绑定
                        scrollDelDom.addEventListener("scroll", this.scrollMoreDelData, false); //添加绑定
                    }
                })
            }else if(val == this.$getStringObj.getString(this.$Strings.Mind_Group_Upgrade_Member_Limit)){
               
               this.upgradeMemberLimit();

            }
        },

        moveMenu (e,show,menutype) {  //节点的e对象。是否展示菜单。展示哪种类型的菜单。
            this.menuList = [
                {
                    name: this.$getStringObj.getString(this.$Strings.Mind_Group_Upgrade_Member_Limit),
                },
                {
                    name: this.$getStringObj.getString(this.$Strings.Mind_Group_Invite_New_Members),
                },
                {
                    name: this.$getStringObj.getString(this.$Strings.Mind_Group_Delete_Members),
                }
            ]
            this.pointObj = e.target.getBoundingClientRect();
            if (show) {
                this.setgroupAdminMenu(true);
            } else {
                this.setgroupAdminMenu(false);
            }
        },

        //隐藏模态框
        hidden(show) {
            this.showModal = show
                if (show == false) {
                let item = {
                    show: false
                }
                this.$emit("hidden", item);
            }
        },

        searchButton() {
            if (this.isAdminType == 'admin' || this.isAdminType == 'searchAdmin') {
                if (this.searchText == '') {
                    this.refreshGroupMembers()
                    this.isAdminType = 'admin'
                } else {
                    let searchRequestData = {
                        groupId: this.groupDataDetail.id,
                        query: this.searchText,
                        nonAdmin: false 
                    }
                    this.pullGroupSearchGmembers(searchRequestData).then(res => {
                        if(res != null) {
                            this.searchAdminListData = res.members ;
                        }
                    });
                    this.isAdminType = 'searchAdmin'
                }
            }
            if ( this.isAdminType == 'rember' || this.isAdminType ==  'searchRember') {
                if (this.searchText == '') {
                    this.refreshGroupMembers()
                    this.isAdminType = 'rember'
                } else {
                    let searchRequestData = {
                        groupId: this.groupDataDetail.id,
                        query: this.searchText,
                        nonAdmin: false 
                    }
                    this.pullGroupSearchGmembers(searchRequestData).then(res => {
                        if(res != null) {
                            this.searchRembersListData = res.members ;
                            if (this.searchRembersListData != null) {
                                for (let index = 0; index < this.searchRembersListData.length; index++) {
                                    this.searchRembersListData[index].isNeedDelet = false;
                                }
                            }
                        }
                    });
                    this.isAdminType =  'searchRember'
                }
            }
        },

        clearSearchText() {
            this.searchText = "";
            this.searchButton()
        },

        httpImageToPrefix(src) {    //拼接图片地址
           return httpImageToPrefix(src)
        },

        timestampToTimeDHM(timestamp) {
            return timestampToTime.formatTime(timestamp);
        },

        //获取所有组员信息
        refreshGroupMembers() {     
            this.pullGroupMembers({ groupId: this.groupDataDetail.id, lastId: '' }).then(res => {
                this.gropuMembers = res;

                if (this.gropuMembers != null && this.gropuMembers.admins != null) {
                    for (let index = 0; index < this.gropuMembers.admins.length; index++) {
                        this.gropuMembers.admins[index].isNeedDelet = false;
                    }
                }

                if (this.gropuMembers != null && this.gropuMembers.members != null) {
                    for (let index = 0; index < this.gropuMembers.members.length; index++) {
                        this.gropuMembers.members[index].isNeedDelet = false;
                    }
                    this.gropuMembers.admins = this.gropuMembers.admins.concat(this.gropuMembers.members);
                }

                this.$nextTick(() => {
                    let scrollDom = document.getElementById("group-members-big-box-children");
                    if (scrollDom != undefined) {
                        scrollDom.removeEventListener("scroll", this.scrollMoreData); //解除绑定
                        scrollDom.addEventListener("scroll", this.scrollMoreData, false); //添加绑定
                    }
                });

            })
        },

        scrollPullGroupMembers() {

        },

        pushGroupDeleteGmembers(obj) {
            return new Promise((resolve, reject) => {
                postGroupDeleteGmembers(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

        pullGroupSearchGmembers(obj) {
            return new Promise((resolve, reject) => {
                postGroupSearchGmembers(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

        pullGroupMembers(obj) {
            return new Promise((resolve, reject) => {
                postGroupMembers(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },
        //用户中心
        getUserProfile(id) {
        this.showUserDataModel({
            accout: true,
            userId: id,
            xfrom: "crisps",
            fromId: id,
        });
        },
    },
    watch: {
        show(newVal) {
            this.showModal = newVal
            if (newVal == true) { //显示模态框请求一次数据
                this.refreshGroupMembers();
            }
        },
        groupDataDetail: {
            handler(newVal,oldVal) {
                if (newVal != null) {
                }
            },
            immediate: true,
            deep: true
        }
    }
}
</script>

<style lang="less" scoped>
    // .invitation-group:hover {
    //    color: #f05446;
    // }
    .members-each:hover {
        background-color: #eee;
    }
    .group-add-new-group-box {
        position: fixed;
        left: 0; 
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.1);
        z-index: 10;
        .center-writer {
            width: 614px;
            min-height: 400px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background-color: #fff;
            border-radius: 10px;
            .group-setting-administrator-header{
                display: flex;
                padding: 8px 20px;
                background: #efefef;
                border-radius: 10px 10px 0 0;
                .administrator-header-left{
                    flex: 0 0 100px;
                    text-align: center;
                    font-size: 12px;
                }

                .administrator-header-main{
                    flex: 1;
                    width: 100%;
                    // text-align: center;
                    color: #333;
                    font-size: 16px;
                }

                .administrator-header-right{
                    flex: 0 0 100px;
                    text-align: right;
                    font-size: 12px;
                    img {
                        margin-right: 0px;
                    }
                    .hidden-icon {
                        width: 16px;
                        cursor: pointer;
                    }
                }

            }
            .close-modal {
                position: absolute;
                right: 0;
                top: 0;
                width: 40px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                cursor: pointer;
                font-size: 16px;
                color: #333;
                border-radius: 0 10px 0 0;
            }
            .search-header-input {
                width: 574px;
                height: 32px;
                margin: 0 auto;
                margin-top: 20px;
                margin-bottom: 0px;
                .search-left-search {
                    width: 100%;
                    height: 32px;
                    margin-left: 0px;
                    position: relative;

                    .search-right-icon {
                        width: 30px;
                        height: 30px;
                        line-height: 32px;
                        color: #bbb;
                        font-size: 18px;
                        // background-color: red;
                        position: absolute;
                        right: 0;
                        top: 0;
                        cursor: pointer;
                        &:hover {
                            color: #ff7354;
                        }
                        &:active {
                            color: #d6301a;
                        }
                    }

                    .search-search-button {
                        position: absolute;
                        margin-left: 10px;
                        margin-top: 3px;
                        border: 0px;
                        background: none;
                    }

                    .ant-btn:hover,
                    .ant-btn:focus {
                        background: #fd492b;
                        color: #fff;
                        opacity: 1;
                        border: none;
                    }

                    .search-search-box {
                        width: 100%;
                        border-radius: 15px;
                        border: transparent;
                        background: #F0F2F8;
                        height: 30px;
                        padding-left: 35px;
                    }

                    .search-search-box:focus {
                        outline: none;
                    }
                }
            }

        }

        .group-members-big-box {
            padding-bottom: 32px;
            .group-members-title {
                padding: 8px 20px;
                margin-top: 20px;
                color: #999999;
                background-color: #F0F2F8;
            }
            .group-members-big-box-children {
                margin-top: 10px;
                // padding: 0 2%;
                padding-bottom: 0px;
                // max-height: 264px;
                // min-height: 100px;
                height: 240px;
                overflow-y: auto;
                background: #f8f8f8;
                width: 96%;
                margin-left: 2%;
                .members-each {
                    display: flex;
                    padding: 0 10px;
                    width: 100%;
                    height: 62px;
                    // margin: 0 20px;
                    justify-content: space-between;
                    border-bottom: 1px solid #dedede;
                    border-radius: 10px;
                    cursor: pointer;
                    .members-each-left {
                        display: flex;
                        align-items: center;
                        .groupInvitation-user-box{
                            position: relative;
                            left: 6px;
                            margin-right: 12px;
                            // height: 60px;
                            // width: 60px;
                            display: flex;
                            align-items: center;
                        }
                        .groupInvitation-user {
                            width: 100%;
                            overflow: hidden;
                            // transform: scale(.8);
                        }
                        .default-circle {
                            border-radius: 50%;
                            width: 14px;
                            height: 14px;
                            margin-right: 10px;
                            border: 1px solid #999;
                        }
                        .check-icon {
                            margin-right: 10px;
                        }
                        .members-cover {
                            width: 20px;
                            height: 20px;
                            margin-right: 8px;
                        }
                        .members-name {
                            color: #333;
                            margin-left: 8px;
                        }
                    }
                    .members-each-right {
                        line-height: 60px;
                        color: #999;
                    }
                }

            }
        
            .group-members-big-box-children-content {
                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        .group-members-footer-menu {
            position: absolute;
            bottom: 10px;
            height: 32px;
            line-height: 32px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding-left: 20px;
            padding-right: 20px;
            // .check-rembers-box {

            // }
            .all-click-button-box {
                // display: flex;
                text-align: right;
                span {
                    margin: 0 15px;
                    cursor: pointer;
                    &:hover {
                        color: #fd492b;
                    }
                }

            }
        }

    }


</style>