<template>
    <div class="group-data">
        <div v-if="groupDataDetail != null" class="group-data-center">
            <div v-if="userImage == ''" @click="UpUserHeader" class="group-add-header">
                <div class="level-line">
                </div>
                <div class="vertical-line"></div>
            </div>
            <div v-else @click="UpUserHeader" :style="{ backgroundImage: 'url('+ httpImageToPrefix(userImage) +')', }" class="group-add-header-box group-header-img">
            </div>
            <div class="group-data-center-name">
                {{ groupName }}
            </div>
            <div class="group-data-center-id">
                {{ $getStringObj.getString($Strings.Mind_Group_Group_ID) }}:{{ groupDataDetail.code }}
            </div>

            <div class="group-detail-list">
                <label >
                <span class="group-detail-name">
                    {{ $getStringObj.getString($Strings.Mind_Group_Name) }}
                </span>
                <input
                class="group-detail-input"
                v-model="groupName"
                @input="inputArea()"
                :placeholder="$getStringObj.getString($Strings.Mind_Group_Please_Enter_A_Shared_Group_Name)"
                >
                </label>
                <div class="group-name-warning">
                    {{groupNameWarning}}
                </div>
            </div>

            <div class="group-detail-list">
                <label >
                <span class="group-detail-name">
                    {{ $getStringObj.getString($Strings.Mind_Group_Introduce) }}
                </span>
                <input
                class="group-detail-input"
                v-model="groupIntroduce"
                @input="inputIntroduce()"
                :placeholder="$getStringObj.getString($Strings.Mind_Group_Please_Give_A_Brief_Introduction_To_Shared_Groups)"
                >
                </label>
            </div>

            <div class="group-detail-list">
                <label >
                <span class="group-detail-name">
                    {{ $getStringObj.getString($Strings.Mind_Group_Announcement) }}
                </span>
                <input
                class="group-detail-input"
                v-model="notice"
                placeholder=""
                @input="inputNoticeArea()"
                >
                </label>
                <div class="group-name-warning">
                    {{noticeWarning}}
                </div>
            </div>

            <div class="group-tag">
                <div class="group-tag-name">{{ $getStringObj.getString($Strings.Mind_Group_Shared_Group_Tag) }}</div>
                <div class="group-tag-right">
                    <div class="group-my-tag-list">
                        <div v-for=" (item,index) in myTagList" :key="index" @click="removeGroupTag(item,index)" class="group-my-tag-button">
                            <span  class="group-my-tag-button-text">{{ item }}</span> 
                            <img class="label-close-img" src="../../../../assets/img/popupsImg/colse_new.svg" />

                        </div>
                        <div @click="clickCreatedTag" class="group-all-tag-button">
                            +{{ $getStringObj.getString($Strings.Mind_Group_Custom_Tag) }}
                        </div>
                    </div>

                </div>

            </div>
            <!-- <div class="group-all-tag-list-box">
                <div class="group-all-tag-list">
                    <div @click="clickCreatedTag" class="group-all-tag-button">
                        +{{ $getStringObj.getString($Strings.Mind_Group_Custom_Tag) }}
                    </div>
                </div>
            </div> -->
            <div class="group-save-button-box">
                <div @click="saveGroupData()" class="group-save-button">
                    {{ $getStringObj.getString($Strings.Mind_Group_Save) }}
                </div>
            </div>

            <GroupHeaderImageModal :defaultHeaderList="defaultHeaderList" :show="showImageModal" @hiddenImageModal="hiddenImageModal"  />
            <GroupTagCreated v-if="showTag" :show="showTag" :defaultLabelList="[]" @hiddenTap="hiddenTap" @selectedLabeList="selectedLabeList"/>
        </div>
    </div>
</template>

<script>
import GroupTagCreated from "../../GroupTagCreated";
import GroupHeaderImageModal from "../../groupCreatedChild/GroupHeaderImageModal";
import httpImageToPrefix from '../../../../utils/httpImageToPrefix';
import { mapMutations } from "vuex";
import { postGroupListSystemGcovers, postGroupGsettings,
 postGroupChangeGcover, postGroupChangeName, 
 postGroupChangeDesc, postGroupChangeTags, 
 postGroupChangeNotice} from "../../../../common/netWork/group_api";
export default {
    components: {
        GroupTagCreated,
        GroupHeaderImageModal
    },
    data() {
        return {
            pullGroupSetting: null,// 组的设置信息。
            userImage: '', //图片地址
            defaultHeaderList: [], //图片数组
            showImageModal: false, //图片弹框
            showTag: false, //展示标签
            groupDataDetail: null, //共享组详情

            groupName: '', //共享组名称
            groupNameWarning: '', //共享组名称警告
            groupIntroduce: '', // 共享组介绍
            notice: '', //公告
            noticeWarning: '', //公告警告

            myTagList: [], //我的共享组的标签
            // allTagList: [], //所有共享组的标签
        }
    },
    created() {
        this.pullDefaultCover({}).then(res => {
            this.defaultHeaderList = res.covers
            // this.userImage = this.defaultHeaderList[0]
        })
    },
    methods: {
        ...mapMutations([
            "setGroupDataDetail",
            "setRefreshMyGroupList"
        ]),
        httpImageToPrefix(src) {    //拼接图片地址
           return httpImageToPrefix(src)
        },

        saveGroupData() {
            let obj = {};
            this.groupName = this.trim(this.groupName) 
            if(this.groupName == '') {
                this.groupNameWarning = this.$getStringObj.getString(this.$Strings.Mind_Group_The_Group_Name_Cannot_Be_Empty);
            } else {
                let req = { cover: this.userImage,
                name: this.groupName,
                desc: this.groupIntroduce,
                tags: this.myTagList
                }
                Promise.all([this.setGroupCover({cover: this.userImage, groupId: this.groupDataDetail.id}) ,
                this.setGroupName({name: this.groupName, groupId: this.groupDataDetail.id}),
                this.setGroupDesc({desc: this.groupIntroduce, groupId: this.groupDataDetail.id}),
                this.setGroupTag({tags: this.myTagList, groupId: this.groupDataDetail.id}),
                this.setGroupNotice({notice: this.notice, groupId: this.groupDataDetail.id})
                ]).then((results) => {
                    this.groupDataDetail.cover = this.userImage;
                    this.groupDataDetail.name = this.groupName;
                    this.groupDataDetail.desc = this.groupIntroduce 
                    this.groupDataDetail.tags = this.myTagList 
                    this.groupDataDetail.notice = this.notice
                    this.setGroupDataDetail(this.groupDataDetail);
                    this.setRefreshMyGroupList();
                });
            }
        },

        inputArea() {   //输入组名
            if (this.groupName.length > 30){
                this.groupName = this.groupName.slice(0,30);
            }
            this.groupNameWarning = '';
        },

        inputNoticeArea() {   //输入组名
            if (this.notice.length > 512){
                this.notice = this.notice.slice(0,512);
            }
            this.noticeWarning = '';
        },

        inputIntroduce() {
            if (this.groupIntroduce.length > 120){
                this.groupIntroduce = this.groupIntroduce.slice(0,120);
            }
        },

        trim(str) { //判断有首尾空格去除空格
            return str.replace(/^\s+|\s+$/g, '');
        },
        
        hiddenImageModal(obj) {
            if (obj != null) {
                if (obj.show == false) {    //设置模态框隐藏
                    this.showImageModal = false;
                    this.userImage = obj.src;
                }
            }
        },
        //修改头像
        UpUserHeader() {
            this.showImageModal = true;
            // this.$el.querySelector(".hiddenInput").click();
        },

        clickCreatedTag() {
            this.showTag = true;
        },

        hiddenTap(obj) {
            if (obj != null) {
                if (obj.show == false) {    //设置模态框隐藏
                    this.showTag = false;
                    if (obj.groupTagName != '') {
                        this.addGroupTag(obj.groupTagName);
                    }
                }
            }
        },
        selectedLabeList(obj){
            this.myTagList = [...this.myTagList,...obj]
            this.myTagList = Array.from(new Set(this.myTagList))//去掉重复项
        },
        addGroupTag(str) {
            if (typeof(str) !== 'string') {
                return
            }
            if (this.myTagList.indexOf(str) == -1) {
                this.myTagList.push(str);
            }
        },

        removeGroupTag(item,index) { //删除组内标签
            this.myTagList.splice(index,1);
            // if (this.allTagList.indexOf(item) == -1) {
            //     this.allTagList.push(item);
            // }
        },

        // removeAddGroupTag(item,index) { //删除所有标签中的某个标签
        //     this.allTagList.splice(index,1);
        //     if (this.myTagList.indexOf(item) == -1) {
        //         this.myTagList.push(item);
        //     }
        // },

        setGroupNotice(obj) { //设置组公告
            return new Promise((resolve, reject) => {
                postGroupChangeNotice(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

        setGroupTag(obj) { //设置组标签
            return new Promise((resolve, reject) => {
                postGroupChangeTags(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

        setGroupDesc(obj) { //设置组介绍
            return new Promise((resolve, reject) => {
                postGroupChangeDesc(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

        setGroupName(obj) { //设置组名称
            return new Promise((resolve, reject) => {
                postGroupChangeName(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

        setGroupCover(obj) { //设置头像
            return new Promise((resolve, reject) => {
                postGroupChangeGcover(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },
        
        pullGroupSettingData(obj) {  //获取组数据
            return new Promise((resolve, reject) => {
                postGroupGsettings(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

        pullDefaultCover(obj) {  //获取默认头像
            return new Promise((resolve, reject) => {
                postGroupListSystemGcovers(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

    },

    watch: {
        "$store.state.groupDataDetail" : {
            handler(newVal,oldVal) {
                if (newVal != null) {
                    this.groupDataDetail = newVal
                    this.userImage = this.groupDataDetail.cover
                    this.groupName = this.groupDataDetail.name
                    this.groupIntroduce = this.groupDataDetail.desc
                    this.myTagList = this.groupDataDetail.tags
                    if(this.groupDataDetail.tags == null || this.groupDataDetail.tags == ''){
                        this.myTagList = [];
                    }
                    this.notice = this.groupDataDetail.notice
                    this.pullGroupSettingData({ groupId: this.groupDataDetail.id}).then(res => {
                        this.pullGroupSetting = res;
                    });
                }
            },
            immediate: true,
            deep: true
        }
    }

}
</script>

<style lang="less">
    .group-data {
        .group-data-center {
            .group-add-header-box {
                margin: 30px auto;
                margin-bottom: 20px;
                width: 120px;
                height: 120px;
                position: relative;
                background-color: #F2F2F2;
                border-radius: 5px;
                cursor: pointer;
                .level-line {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    width : 56px;
                    height: 5px;
                    background-color: #999999;
                    border-radius: 2.5px;
                }
                .vertical-line {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    width: 5px;
                    height: 56px;
                    background-color: #999999;
                    border-radius: 2.5px;
                }
            }
            .group-header-img {
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
            }
            .group-data-center-name {
                text-align:center;
                color: #333;
                font-size: 14px;
                margin-bottom: 6px;
            }
            .group-data-center-id {
                text-align:center;
                color: #999;
                font-size: 14px;
                margin-bottom: 40px;
            }

            .group-detail-list {
                // width: 400px;
                // width: 796px;
                font-size: 14px;
                line-height: 40px;
                color: #333333;
                margin: 0 auto;
                margin-bottom: 30px;
                .group-detail-name {
                    width: 156px;
                    display: inline-block;
                    text-align: right;
                    margin-right: 20px;

                }
                .group-detail-input {
                    width: calc(100% - 200px);
                    // width: 745px;
                    height: 38px;
                    border: 1px solid #E2E2E2;
                    background-color: #F0F2F8;
                    padding-left: 10px;
                    outline: none;
                    border-radius: 2px;
                    font-size: 14px;
                }
                .group-name-warning {
                    color: #FD492B;
                    padding-left: 95px;
                }
            }

            .group-tag {
                // width: 400px;
                margin: 0 auto;
                margin-bottom: 20px;
                display: flex;
                // justify-content: center;
                align-items: center;
                color: #333;
                .group-tag-name {
                    font-size: 14px;
                    margin-right: 20px;
                    display: block;
                    width: 156px;
                    text-align: right;
                }
                .group-tag-right {
                    width:calc(100% - 200px);
                    .group-my-tag-list {
                        // width: 300px;
                        // width: 745px;
                        min-height: 38px;
                        // border: 1px solid #E2E2E2;
                        // background-color: #F0F2F8;
                        font-size: 14px;
                        border-radius: 2px;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        .group-my-tag-button {
                            display: flex;
                            align-items: center;
                            height: 28px;
                            margin: 5px 6px;
                            line-height: 28px;
                            padding: 0 12px;
                            background-color: rgba(236,112,90,.3);
                            border-radius: 14px;
                            cursor: pointer;
                            .group-my-tag-button-text {
                                // margin-right: 3px;
                                color: #EC705A;
                            }
                           .label-close-img{
                                width:16px;
                                margin-left: 8px;
                                opacity: .3;
                            }
                        }

                        .group-all-tag-button {
                            display: inline-block;
                            height: 36px;
                            line-height: 36px;  
                            font-size: 14px;
                            padding: 0 18px; 
                            border-radius: 18px;
                            color: #333;
                            background-color: #F0F2F8;
                            margin-right: 15px;
                            margin-bottom: 4px;
                            cursor: pointer;
                        }
                    }
                }

            }


            // .group-all-tag-list-box {
            //     width: 922px;
            //     padding-left: 176px;;
            //     // margin: 0 auto;
            //     margin-bottom: 20px;
            //     .group-all-tag-list {
            //         .group-all-tag-button {
            //             display: inline-block;
            //             height: 36px;
            //             line-height: 36px;  
            //             font-size: 14px;
            //             padding: 0 18px; 
            //             border-radius: 18px;
            //             color: #333;
            //             background-color: #F0F2F8;
            //             margin-right: 15px;
            //             margin-bottom: 4px;
            //             cursor: pointer;
            //         }
            //     }
            // }

            .group-save-button-box {
                margin-top: 20px;
                margin-bottom: 30px;
                text-align: center;
                .group-save-button {
                    display: inline-block;
                    margin: 0 auto;
                    font-size: 16px;
                    height: 32px;
                    line-height: 32px;
                    text-align: center;
                    padding-left: 20px;
                    padding-right: 20px;
                    border-radius: 19px;
                    background-color: #FD492B;
                    color: #fff;
                    cursor: pointer;
                    position: relative;
                }
                .group-save-button:hover {
                    background-color: #FD492B;
                    color: #fff;
                }
                .group-save-button:active {
                    left: 1px;
                    top: 1px;
                }
            }

            
        }
    }
</style>