<template>
    <div>
        <div v-show="showPage" :style="{left:pointLeft + 'px',top: pointTop + 'px',opacity: opacity}" ref="addGroup"  @mouseover="moveMenu(true)" @mouseout="moveMenu(false)" class="add-group">
            <div :style="{position:'absolute',left: hiddenLeft + 'px',
            top: hiddenTop + 'px',width:hiddenWidth + 'px',height: hiddenHeight + 'px'}" class="hidden-dom"></div>
            <div v-for="(item,index) in showData" :key="index"
                @click=" checkMenuItem(item)" class="menulist-each">
                <img :src="item.image"  alt="">
                <span>
                    {{item.name}}
                </span>
            </div>
        </div>
        <GroupAddGroupModal :show="showModal" @hidden="hidden" />
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import GroupAddGroupModal from "./GroupAddGroupModal"
import { getJwt } from "@/common/netWork/base";

export default {
    components: {
        GroupAddGroupModal
    },
     props: [
        "showData",
        "pointObj",
    ],
    data() {
        return {
            showPage: false,
            pointLeft: 0,   //大盒子相对于整个页面的位置
            pointTop: 0,
            hiddenLeft: 0, //透明盒子相对与父盒子的位置
            hiddenTop: 0,
            hiddenWidth: 0,
            hiddenHeight: 0,
            opacity: 0,
            showAnimal:false,
            showModal: false,
        }
    },
    methods: {
        ...mapMutations([
            "setgroupCreatedMenu",
            "showLoginModel"

        ]),
        moveMenu (show) {
            if (show) {
                this.setgroupCreatedMenu(true);
            } else {
                this.setgroupCreatedMenu(false);
            }
        },
        checkMenuItem (item) {
            this.$emit("checkMenuItem", item.name);
            if (item.name == this.$getStringObj.getString(this.$Strings.Mind_Group_Join_The_Group)) {
                if (!getJwt()) {
                    this.showLoginModel(true);
                    return
                }
                this.showModal = true;
            } 
        },
        hidden(obj) {
            if (obj.show == false){
                this.showModal =false
            }
        }
    },
    watch: {
        "$store.state.groupCreatedMenu"(newModelAccout) {    //如果把所有参数都放在vuex里面，会遇到获取dom宽高还有位置延迟的问题，导致计算不准。还有深度监听的频繁触发的问题
            this.showPage = newModelAccout;
            // this.showPage = true;
        },
        pointObj(val) { 
            //一开始先移入了节点，这时显示菜单的位置不对，获取不到菜单的宽高，所以先让菜单透明，并且由于菜单的一部分刚好出现在鼠标下，
            //这时又触发了鼠标移出事件。这时能获取到菜单的大小了。所以能正常显示菜单的位置了。
            let menudom = this.$refs.addGroup.getBoundingClientRect();
            if (menudom.width == 0) {   //为了避免在其它位置闪一下。
                this.opacity = 0;
            } else {
                this.opacity = 1;
            }
            this.pointTop = val.y + val.height;
            this.pointLeft = val.x - menudom.width + val.width;
            this.hiddenLeft = menudom.width - val.width;
            this.hiddenTop = -val.height;
            this.hiddenWidth = val.width;
            this.hiddenHeight = val.width;
        }
    }
}
</script>

<style lang="less" scoped>
    .add-group {
        position: fixed;
        left: 200px;
        top: 200px;
        padding: 16px 0;
        border-radius: 4px;
        background-color: #fff;
        // box-shadow: 0 0 5px #eee;
        z-index: 2;
        box-shadow: 0 0 5px #999;

        .menulist-each {
            // max-height: 20px;
            line-height: 20px;
            font-size: 14px;
            white-space: nowrap;
            display: flex;
            align-items: center;
            
            color: #333;
            padding: 5px 25px;
            cursor: pointer;
            img {
                margin-right: 5px;
                width: 16px;
                height: 16px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        .menulist-each:hover {
            background-color: #eee;
        }
        
        .hidden-dom {
            // background-color:slateblue;
        }
    }
</style>