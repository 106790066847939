<template>
    <div :style="{ height: imgHeight + 'px' }" class="header-img">
        <template>
        
        </template>
        <div :style="{ height: imgHeight + 'px' }" class="member-style" v-if="isMember">
                <img 
                    :style="{
                        width: imgWidth + 'px',
                    }"
                class="member-crown"
                src="../../assets/img/user/crown.png"
                alt
                />
            <div 
                class="group-new-img"
                :style="{
                        width: imgWidth + 'px',
                        height: imgWidth + 'px',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                        overflow: 'hidden',
                        backgroundImage: 'url('+ httpImageToPrefix(imgUrl) +')',
                    }"
            ></div>
        </div>
        <div v-else>

        </div>

    </div>
</template>

<script>
import httpImageToPrefix from '../../utils/httpImageToPrefix';
export default {
    props: {
        imgUrl: {
            type: String,
            defualt() {
                return "";
            },
        },

        imgWidth: {
            type: Number,
            defualt() {
                return 0;
            },
        },

        imgHeight: {
            type: Number,
            defualt() {
                return 0;
            },
        },
        
        isMember: {
            type: Boolean,
            defualt() {
                return false;
            },
        }
    },
    methods: {
        httpImageToPrefix(src) {    //拼接图片地址
           return httpImageToPrefix(src)
        },
    }
}
</script>

<style lang="less" scoped>
    .member-style {
        position: relative;
        .member-crown {
            position: absolute;

        }
        .group-new-img {
            position: absolute;
            z-index: 1;
            bottom: 1%;
        }
    }
</style>