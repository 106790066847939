<template>

  <div class="group-tag-page" @click.stop="cklickModal(false)" v-show="tagModalShow" style="position: absolute;z-index: 9999;">
      <div @click.stop="cklickModal(true)" class="group-tag-page-center">
            <div class="group-tag-detail-list">
                <div class="group-tag-detail-name">
                    <div class="group-tag-detail-name-title">
                        {{ getString(Strings.Mind_Group_Invite_New_Members) }}
                    </div>
                    <div class="group-tag-detail-name-close">
                        <img class="close-button-img" src="../../../assets/img/popupsImg/colse_new.svg"  @click.stop="cancel()" />
                    </div>
                </div>
                <div style="padding: 0 20px;">
                    <input
                    class="group-tag-detail-input"
                    v-model="groupTagName"
                    @input="inputArea()"
                    v-on:keyup.enter="affirm()"
                    :placeholder="getString(Strings.Mind_Group_Please_Enter_Your_Cell_Phone_Number)"
                    >
                    <div class="tag-warning">
                        {{warning}}
                    </div>
                </div>
            </div>
            <div class="group-tap-footer">
                <div @click.stop="cancel()" class="group-tap-footer-button button-class-colse">
                    {{ getString(Strings.Mind_Group_Cancel) }}
                </div>
                <div @click.stop="affirm()" class="group-tap-footer-button button-class-ok">
                    {{ getString(Strings.Mind_Group_Confirm) }}
                </div>
            </div>
      </div>
  </div>
</template>

<script>
import { postGroupInvite } from "../../../common/netWork/group_api";
import Strings from "../../../common/lang/strings";
import getString from "../../../common/lang/language";
export default {
     props: [
        "show",
        "groupDataDetail",
    ],
    data() {
        return {
            tagModalShow: false,
            groupTagName:'',
            confrim: false,
            warning: '',
            Strings:Strings,
        }
    },
    methods: {
        getString(i) {
            return getString(i);
        },
        changeTitle(item) {
            this.changeVal = item
        },
        cklickModal(val) {
            this.tagModalShow = val;
            if (val == false) { //当隐藏模态框时
                this.hiddenTap({show:false, confrim: this.confrim, inputText: this.trim(this.groupTagName)})
                this.groupTagName = ''
                this.warning = ''
            }
        },
        hiddenTap(obj) {
            this.$emit("hiddenInput", obj);
        },
        affirm() {
            if (this.trim(this.groupTagName) == '') {
                this.warning = getString(Strings.Mind_Group_The_Phone_Number_Cannot_Be_Blank)
                return
            }
            this.confrim = true;
            this.pullGroupInvite({ phone: this.trim(this.groupTagName), groupId: this.groupDataDetail.id}).then(res=> {
                this.cklickModal(false);
                this.confrim = false;
                this.$message.success(getString(Strings.Mind_Group_Invite_The_Successful));
            });
        },
        cancel() {
            this.confrim = false;
            this.cklickModal(false);
        },
        trim(str) { //判断有首尾空格去除空格
            return str.replace(/^\s+|\s+$/g, '');
        },
        inputArea() {   //不允许输入或黏贴超过20个字符
            this.warning =''
            if (this.groupTagName.length > 20){
                this.groupTagName = this.groupTagName.slice(0,20);
            }
        },
        pullGroupInvite(obj) {
            return new Promise((resolve, reject) => {
                postGroupInvite(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },
    },
    watch: {
        show: {
            handler(newval, olval) {
                this.tagModalShow = newval
            },
            deep: true,
        },
        groupTagName: {
            handler(newval, olval) {

            },
        }
    }
}
</script>

<style lang="less" scoped>
    .group-tag-page {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,.3);
        .group-tag-page-center {
            position: absolute;
            width: 300px;
            min-height: 150px;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background-color: #fff;
            border-radius: 4px;
            .group-tag-detail-list {
                width: 100%;
                font-size: 14px;
                line-height: 40px;
                color: #333333;
                margin: 0 auto;
                margin-bottom: 20px;
                .group-tag-detail-name {
                    // text-align: center;
                    font-size: 16px;
                    margin-bottom: 10px;
                    text-align: left;
                    font-size: 16px;
                    margin-bottom: 20px;
                    height: 40px;
                    position: relative;
                    padding: 0px 20px;
                    background: #efefef;
                    border-radius: 4px 4px 0 0;
                    .group-tag-detail-name-title{
                        position: absolute;
                    }
                    .group-tag-detail-name-close{
                        position: relative;
                        float: right;
                        .close-button-img{
                            width:16px;
                            cursor: pointer;
                            opacity: 0.5;
                        }
                        .close-button-img:hover{
                            opacity: 0.8;
                        }
                    }
                }
                .group-tag-detail-input {
                    width: 100%;
                    height: 38px;
                    border: 1px solid #E2E2E2;
                    background-color: #F0F2F8;
                    outline: none;
                    border-radius: 2px;
                    font-size: 12px;
                }
                .tag-warning {
                    color: #FD492B;
                }
            }
            .group-tap-footer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                .group-tap-footer-button {
                    padding: 0 12px;
                    margin: 0 20px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    border-radius: 2px;
                    font-size: 14px;
                    // color: #333;
                    background-color: #fff;
                }
                .button-class-ok{
                    background: #FD492B;
                    color: #fff;
                }
                .button-class-colse{
                    border: 1px solid #F0F2F8;
                    color: #999999;
                }
                .button-class-colse:hover {
                    color: #FD492B;
                    border: 1px solid #FD492B;
                    background-color: #fff;
                }
                .button-class-ok:hover{
                    background: #ff7354;
                }
                // .group-tap-footer-button:hover {
                //     background-color: #FD492B;
                //     color: #fff;
                // }
            }
        }
    }
</style>